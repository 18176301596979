import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UserService} from '@process-manager/pm-library';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {NodeType} from '../../../shared/model/treeelement';
import {ReportService} from '../../../shared/services/report.service';

interface SearchElement {
  label: string;
  id: number;
  type: NodeType;
  pos: number;
  path: string;
  parent: number;
  parentPage: number;
}

interface DSPermission {
  writePermission: boolean
}

interface DSGroup extends DSPermission {
  groupID: number,
  groupName: string,
  groupExternalID: string
}

interface DSUser extends DSPermission {
  userID: number,
  userName: string,
  userFirstName: string,
  userLastName: string
}

interface DataSourceElement extends SearchElement {
  extraData: {
    groupPerms: [DSGroup], userPerms: [DSUser]
  }
}

@Component({
  selector: 'pm-permission-list',
  templateUrl: './permission-list.component.html',
  styleUrls: ['./permission-list.component.css']
})
export class PermissionListComponent implements OnInit, OnDestroy {
  folded = false;
  searchResultsSubscription: Subscription;
  loading = true;
  displayedColumns = ['label', 'path', 'users', 'groups'];
  dataSource = new MatTableDataSource<DataSourceElement>();
  activeSearchElement: any = null;

  constructor(private userService: UserService, private http: HttpClient, private reportService: ReportService) {
  }

  ngOnInit() {
    const url = environment.api + this.userService.domain + '/permissions';
    const compareFn = (a, b) => (a?.label || '').localeCompare(b?.label || '', undefined, {numeric: true});
    this.searchResultsSubscription =
      this.http.get<DataSourceElement[]>(url).pipe(finalize(() => this.loading = false)).subscribe(result => {
        this.dataSource.data = this.reportService.translateBackupElement(result);
      });
  }

  ngOnDestroy() {
    this.searchResultsSubscription.unsubscribe();
  }

  navigateToNode(searchElement: DataSourceElement) {
    this.activeSearchElement = searchElement.id;
    this.reportService.navigate(searchElement);
  }

  fullname(user: DSUser) {
    return [user.userFirstName, user.userLastName].join(' ').trim();
  }
}
