import {Action} from '@ngrx/store';
import {Language} from '@process-manager/pm-library';
import {User} from '@process-manager/pm-library/lib/model/user';
import * as Immutable from 'immutable';
import {Bullet} from '../../shared/model/bullet';
import {Link} from '../../shared/model/link';
import {isProcess, Process, Size} from '../../shared/model/process';
import {RenderedElement} from '../../shared/model/rendered/rendered-element';
import {RenderedProcess} from '../../shared/model/rendered/rendered-process';
import {Shape} from '../../shared/model/shape';
import {isTemplateId} from '../../shared/model/template-id';
import {PathElement, Tree, TreeWithLanguages} from '../../shared/model/tree';
import {NodeType, Permissions, TreeElement} from '../../shared/model/treeelement';
import {AuthenticationActions, LOGIN_SUCCESS, LOGOUT, UPDATE_SETTINGS} from '../actions/auth.actions';
import {ApplyFormat, FormatActionTypes} from '../actions/formatting.actions';
import {ListTemplateCompleteAction, TemplateActionTypes} from '../actions/templates.actions';
import {
  AddElementPayload, DeleteTreeElement, Info, ResourceLink, ResourceUpdate, TreeActions, TreeActionTypes,
  UpdateTreeElementLabels, UpdateTreeElementPermissions, Upload
} from '../actions/tree.actions';

const WATERMARK_DEFAULT_OPACITY = 0.3;

export type Position = 'bottomleft' | 'topleft' | 'topright' | 'bottomright' | 'center';

export interface BaseImageSettings {
  link?: Link;
  maintainAspectRatio: boolean;
  source: string;
  filename: string;
  inherited: boolean;
}

export interface LogoSettings extends BaseImageSettings {
  scale: boolean,
  position: Position;
  width?: number;
  height?: number;
}

export interface WatermarkSettings extends BaseImageSettings {
  ratio: number;
  alpha: number;
}

export type ActivationType = 'highlight' | 'active' | 'textEdit' | 'styleEdit' | 'addEdit' | 'scale' | null;

export const findTemplateElement = (renderedElement: RenderedProcess, id: string): Process => {
  const candidates = renderedElement.children
  for (const child of candidates) {
    if (child.id === id) {
      return unRenderElement(child);
    } else {
      const bullet = child.bullets.find(bulletCand => bulletCand.id === id);
      if (!!bullet) {
        return {...unRenderElement(bullet),
          hasUnloadedChildren: true,
          nodes: [],
          bullets: [],
          type: 'mainpage',
          style: child.style,
          label: child.label,
          info: child.info,
          extendedInfo: child.extendedInfo
        }
      }
    }
  }

  return null;
}

const unRenderElement = (renderedElement: RenderedElement): Process|Bullet => {
  return {
    id: renderedElement.id,
    parentId: renderedElement.parentId,
    type: renderedElement.nodeType,
    label: renderedElement.label,
    style: renderedElement.style,
    links: renderedElement.links.map(link => link.id),
    info: renderedElement.info,
    infoColor: renderedElement.infoColor,
    extendedInfo: renderedElement.extendedInfo,
    owner: renderedElement.owner,
    writeable: renderedElement.writeable,
    searchable: renderedElement.searchable,
    permissions: renderedElement.permissions,
  }
}

export interface InheritedDetails {
  owner: string;
  permissions: Permissions;
}

export interface NodeActivation {
  id: string;
  type: ActivationType;
}

interface HistorySnapshot {
  action: Action,
  tree: Tree
}

interface History {
  pastStates: HistorySnapshot[];
  futureStates: HistorySnapshot[];
}

export interface TreeState {
  templateNode: RenderedProcess;
  tree: Tree;
  history: History;
  openNode: string;
  loading: boolean;
  saving: boolean;
  savedWithoutLogging: boolean;
  activeNode: NodeActivation;
  activeLanguage: Language;
}

const initialHistory = {
  pastStates: [],
  futureStates: []
};

export const initialState: TreeState = {
  templateNode: null,
  tree: {
    source: null,
    path: [],
    localPath: [],
    root: null,
    nodes: Immutable.Map(),
    links: Immutable.Map(),
    labels: Immutable.Map(),
  },
  history: initialHistory,
  openNode: undefined,
  loading: false,
  saving: false,
  savedWithoutLogging: false,
  activeNode: null,
  activeLanguage: null,
};

/**
 * Copies an element and all its children.
 *
 * @param parent The parent process,
 * @param source The original source element,
 * @param nodes The current nodes in the state.
 * @param ids A pool of ids to be used for new elements.
 * @param updateParent Update the parent process. Defaults to true.
 * @param forcedBulletChildren Use these bullets instead of originals
 * @return Immutable.Map<string, TreeElement> The mutated nodes.
 */
function recursiveCopy(parent: Process, source: TreeElement | Process, nodes: Immutable.Map<string, TreeElement>,
                       ids: string[], updateParent = true, forcedBulletChildren?: Bullet[]) {
  const oldSourceId = source.id;
  const newSourceId = ids && ids.pop() || oldSourceId;

  let newSource: TreeElement | Process = {
    ...source,
    parentId: parent.id,
    id: newSourceId,
    serverId: undefined,
  };
  if (isProcess(newSource)) {
    newSource = {
      ...newSource,
      nodes: [],
      hasUnloadedChildren: newSource.nodes && newSource.nodes.length > 0 || newSource.hasUnloadedChildren
    } as Process;
  }

  nodes = nodes.set(newSourceId, newSource);

  if (updateParent) {
    parent = {
      ...parent,
      nodes: parent.nodes?.map(id => id === oldSourceId ? newSource.id : id),
      bullets: parent.bullets?.map(id => id === oldSourceId ? newSource.id : id),
    };

    nodes = nodes.set(parent.id, parent);
  }

  if (isProcess(newSource)) { // Yeah, recheck is stupid, but source was reassigned, so needed.
    if (!!forcedBulletChildren) {
      forcedBulletChildren.forEach(bullet => nodes = recursiveCopy(nodes.get(newSource.id), bullet, nodes, ids));
    } else if (!!newSource.bullets) {
      newSource.bullets.forEach(node => nodes = recursiveCopy(nodes.get(newSource.id), nodes.get(node), nodes, ids));
    }
  }

  return nodes;
}

// noinspection JSUnusedLocalSymbols
function assertSwitchExhausted(data: never): never {
  throw new Error('A value was missed!');
}

function getNextNode(tree: Tree, nodeId: string, deleteChecking: boolean) {
  let node = tree.nodes.get(nodeId);
  if (node) {
    if (!deleteChecking && node.type === 'bullet') {
      // Get parent's parent
      node = tree.nodes.get(node.parentId);
      if (node) {
        node = tree.nodes.get(node.parentId);
      }
    }
  }

  return node;
}

function calculateOpenRows(tree: Tree, nodeId: string, deleteChecking: boolean = false): Process[] {
  const root = getRoot(tree) as Process;

  if (!root) {
    return [];
  }

  if (root.type === 'mainpage') {
    return root.nodes.map(id => tree.nodes.get(id) as Process);
  } else {
    const openRows: Process[] = [];
    let node: TreeElement;
    let nextNodeId = nodeId;
    do {
      node = getNextNode(tree, nextNodeId, deleteChecking);

      if (!!node && (deleteChecking || (isProcess(node) && node.nodes && node.nodes.length > 0))) {
        openRows.push(node);
      }
      nextNodeId = node && node.parentId || null;
    } while (nextNodeId && node.id !== root.id);

    if (openRows.length === 0) {
      return [root];
    } else {
      return openRows.reverse();
    }
  }
}

function getUndoHistory(pastStates: HistorySnapshot[], previousTree: Tree, action: TreeActions | ApplyFormat): History {
  switch (action.type) {
    default: {
      return {
        pastStates: [...pastStates, {
          action: action,
          tree: previousTree
        }],
        futureStates: []
      };
    }
  }
}

function immutableMoveItem(arr, position, item) {
  const originalPosition = arr.indexOf(item);
  if (position > originalPosition) {
    position--;
  }
  const clone = [...arr];
  clone.splice(position, 0, clone.splice(originalPosition, 1)[0]);
  return clone;
}

function immutableAddItem(arr, position, newItem) {
  return [...arr.slice(0, position), newItem, ...arr.slice(position)]
}

function addOrMoveItem(nodeIds: string[] | undefined, payload: AddElementPayload, underFirst: boolean,
                       moveItem: boolean): string[] {
  const sourceId = payload.sourceElementId;
  if (!nodeIds) {
    return [sourceId];
  }

  const oldPosition = nodeIds.indexOf(payload.targetElementId);

  switch (payload.relation) {
    case 'under':
      if (underFirst) {
        return moveItem ? immutableMoveItem(nodeIds, 0, sourceId) : [sourceId, ...nodeIds];
      } else {
        return moveItem ? immutableMoveItem(nodeIds, nodeIds.length, sourceId) : [...nodeIds, sourceId];
      }
    case 'before':
      return moveItem ? immutableMoveItem(nodeIds, oldPosition, sourceId) :
        immutableAddItem(nodeIds, oldPosition, sourceId);
    case 'after':
      return moveItem ? immutableMoveItem(nodeIds, oldPosition + 1, sourceId) :
        immutableAddItem(nodeIds, oldPosition + 1, sourceId);
    default:
      assertSwitchExhausted(payload.relation);
  }
}

export function getTargetParent(state: TreeState, payload: AddElementPayload) {
  const targetItem = state.tree.nodes.get(payload.targetElementId);
  let parentProcess: Process;
  if (payload.relation === 'under') {
    parentProcess = targetItem as Process;
  } else {
    parentProcess = state.tree.nodes.get(targetItem.parentId) as Process;
  }
  return parentProcess;
}

function positionElement(state: TreeState, payload: AddElementPayload, sourceParentId?: string,
                         forceSourceItemType?: NodeType) {
  const originalParent = getTargetParent(state, payload);
  const moveItem = sourceParentId === originalParent.id;
  const sourceItemType = forceSourceItemType ||
    (state.tree.nodes.get(payload.sourceElementId) || payload.backupElementData.treeElement).type;

  if (sourceItemType === 'bullet') {
    return {
      ...originalParent,
      bullets: addOrMoveItem(originalParent.bullets, payload, true, moveItem)
    }
  } else {
    let updatedParentSize: Size = originalParent.childSize;
    if (payload.relation === 'under' && !originalParent.hasUnloadedChildren &&
      (!originalParent.nodes || originalParent.nodes.length === 0)) {
      const parentsParent = (state.tree.nodes.get(originalParent.parentId) as Process);
      updatedParentSize = parentsParent && parentsParent.childSize || new Size(1, 1);
    }

    return {
      ...originalParent,
      nodes: addOrMoveItem(originalParent.nodes, payload, false, moveItem),
      childSize: updatedParentSize
    };
  }
}

function hasChanged(targetParent: Process, oldSourceParent: Process) {
  const targetNodes = targetParent.nodes || [];
  const oldSourceNodes = oldSourceParent.nodes || [];

  const targetBullets = targetParent.bullets || [];
  const oldSourceBullets = oldSourceParent.bullets || [];

  if (targetParent.id !== oldSourceParent.id || targetNodes.length !== oldSourceNodes.length || targetBullets.length !==
    oldSourceBullets.length) {
    return true;
  }

  for (let i = 0; i < targetNodes.length; i++) {
    if (targetNodes[i] !== oldSourceNodes[i]) {
      return true;
    }
  }

  for (let i = 0; i < targetBullets.length; i++) {
    if (targetBullets[i] !== oldSourceBullets[i]) {
      return true;
    }
  }

  return false;
}

function deleteElement(sourceElement: TreeElement, originalSourceParent: Process): Process {
  if (sourceElement.type === 'bullet') {
    const index = originalSourceParent.bullets.indexOf(sourceElement.id);
    return {
      ...originalSourceParent,
      bullets: [...originalSourceParent.bullets.slice(0, index), ...originalSourceParent.bullets.slice(index + 1)]
    }
  } else {
    const index = originalSourceParent.nodes.indexOf(sourceElement.id);
    return {
      ...originalSourceParent,
      nodes: [...originalSourceParent.nodes.slice(0, index), ...originalSourceParent.nodes.slice(index + 1)]
    }
  }
}


function calcPermissions(state: TreeState, action: UpdateTreeElementPermissions): Permissions {
  if (action.payload.permissions === null) {
    return null;
  } else {
    return {
      subjects: action.payload.permissions,
      readAll: action.payload.permissions.filter(value => !value.writeable).length === 0,
      writeAll: action.payload.permissions.filter(value => value.writeable).length === 0
    }
  }
}

export function treeStoreReducer(state = initialState,
                                 action: TreeActions | AuthenticationActions | ApplyFormat | ListTemplateCompleteAction): TreeState {
  const pastStates = state.history.pastStates;
  const futureStates = state.history.futureStates;
  switch (action.type) {
    case LOGIN_SUCCESS: {
      const payload = action.payload;
      return {
        ...state,
        activeLanguage: payload.currentLanguage &&
          payload.siteSettings.languages.find(lang => lang.id === payload.currentLanguage) ||
          (payload.siteSettings && payload.siteSettings.languages.find(lang => lang.isDefault)) || null,
      }
    }
    case TemplateActionTypes.LIST_TEMPLATES_COMPLETE: {
      return {
        ...state,
        templateNode: action.payload.templateNodes
      }
    }
    case UPDATE_SETTINGS: {
      const payload = action.payload;
      return {
        ...state,
        activeLanguage: state.activeLanguage &&
          payload.settings.languages.find(lang => lang.id === state.activeLanguage.id) ||
          (payload.settings && payload.settings.languages.find(lang => lang.isDefault)) || null
      }
    }
    case LOGOUT:
      return initialState;
    case TreeActionTypes.LOAD_TREE: {
      const payload = action.payload;

      // TODO: This pre-opening has some not-so-nice side effects when loading.
      const useNewNode = payload && payload.id && (!hasUndo(state.history) || state.tree.nodes.has(payload.id));
      const highlight = payload && payload.highlight;
      return {
        ...state,
        openNode: useNewNode ? payload.id : state.openNode,
        loading: true,
        activeNode: highlight ? {
          type: 'highlight',
          id: payload.id
        } : state.activeNode
      };
    }
    case TreeActionTypes.LOAD_TREE_ERROR:
      return {
        ...state,
        loading: false
      };
    case TreeActionTypes.LOAD_TREE_SUCCESS: {
      const payload = action.payload;
      let newTree = payload.tree;

      const openNodeId = payload.openNode;
      const openedNode = newTree.nodes.get(openNodeId);

      if (!!openedNode) {
        let root = openedNode.id;
        if (newTree.source === 'customer' || newTree.source === 'offline') {
          root = newTree.root;
        }

        let localRootFound = false;
        const localPath = [];
        let currentNode = newTree.nodes.get(openNodeId);
        while (newTree.nodes.has(currentNode.parentId)) {
          if (['mainpage', 'page'].includes(currentNode.type)) {
            if (!localRootFound) {
              localRootFound = true;
              root = currentNode.id;
            }

            if (localRootFound && !payload.tree.path.some(path => path.id === currentNode.id)) {
              localPath.push(new PathElement(currentNode.id, currentNode.label.replace(/<[^>]+>/ig, '')));
            }
          }

          currentNode = newTree.nodes.get(currentNode.parentId);
        }
        newTree = {...payload.tree, root: root, localPath: localPath.reverse()};
      }

      return {
        ...state,
        tree: newTree,
        history: payload.wipeHistory ? initialHistory : state.history,
        loading: false,
        openNode: openNodeId || state.openNode
      };
    }
    case TreeActionTypes.UPDATE_TREE_ELEMENT_LABELS: {
      const updatedElement = state.tree.nodes.get(action.payload.updatedElementId);
      const updatedTree = {
        ...state.tree,
        nodes: state.tree.nodes.set(updatedElement.id, {
          ...updatedElement,
          labels: action.payload.labelIds
        })
      };
      return {
        ...state,
        history: getUndoHistory(pastStates, state.tree, action),
        tree: updatedTree,
      };
    }

    case TreeActionTypes.UPDATE_TREE_ELEMENT_TEXT: {
      const payload = action.payload;

      let nodes = state.tree.nodes;
      if(state.tree.nodes.has(payload.id)) {
        nodes = state.tree.nodes.set(payload.id, {
          ...state.tree.nodes.get(payload.id),
          label: payload.label,
          defText: state.activeLanguage.isDefault ? state.tree.nodes.get(payload.id).defText : false,
          textAutoTranslated: state.activeLanguage.isDefault ? state.tree.nodes.get(payload.id).textAutoTranslated : false
        });
      }
      const updatedTree = {
        ...state.tree,
        nodes: nodes,
        path: state.tree.path.map(pathElement => {
          let newLabel = pathElement.label;
          if (pathElement.id === payload.id) {
            newLabel = payload.label;
          }
          return new PathElement(pathElement.id, newLabel);
        })
      };

      return {
        ...state,
        history: getUndoHistory(pastStates, state.tree, action),
        tree: updatedTree,
      };
    }
    case TreeActionTypes.UPDATE_TREE_ELEMENT_TYPE: {
      const updatedTree = {
        ...state.tree,
        nodes: state.tree.nodes.set(action.payload.id, {
          ...state.tree.nodes.get(action.payload.id),
          type: action.payload.type
        })
      };

      return {
        ...state,
        history: getUndoHistory(pastStates, state.tree, action),
        tree: updatedTree,
      };
    }
    case TreeActionTypes.UPDATE_TREE_ELEMENT_SEARCHABLE: {
      const updatedTree = {
        ...state.tree,
        nodes: state.tree.nodes.set(action.payload.id, {
          ...state.tree.nodes.get(action.payload.id),
          searchable: action.payload.searchable
        })
      };

      return {
        ...state,
        history: getUndoHistory(pastStates, state.tree, action),
        tree: updatedTree,
      };
    }

    case TreeActionTypes.UPDATE_TREE_ELEMENT_STYLE: {
      const updatedElement = state.tree.nodes.get(action.payload.updatedElementId);
      const updatedTree = {
        ...state.tree,
        nodes: state.tree.nodes.set(updatedElement.id, {
          ...updatedElement,
          style: action.payload.style
        })
      };
      return {
        ...state,
        history: getUndoHistory(pastStates, state.tree, action),
        tree: updatedTree,
      };
    }
    case TreeActionTypes.UPDATE_TREE_ELEMENT_SIZE: {
      const payload = action.payload;
      const updatedElement: Process = {
        ...state.tree.nodes.get(payload.parentId),
        childSize: payload.size
      };

      const updatedTree = {
        ...state.tree,
        nodes: state.tree.nodes.set(updatedElement.id, updatedElement)
      };
      return {
        ...state,
        history: getUndoHistory(pastStates, state.tree, action),
        tree: updatedTree
      }
    }
    case TreeActionTypes.UPDATE_TREE_ELEMENT_PERMISSIONS: {
      const payload = action.payload;
      const node = state.tree.nodes.get(payload.id);

      return {
        ...state,
        tree: {
          ...state.tree,
          nodes: state.tree.nodes.set(payload.id, {
            ...node,
            permissions: calcPermissions(state, action),
            owner: payload.owner
          })
        },
        history: getUndoHistory(pastStates, state.tree, action)
      }
    }
    case TreeActionTypes.ADD_RESOURCE: {
      const payload = action.payload;
      let treeElement = state.tree.nodes.get(payload.elementId);

      if (treeElement.defLinks) {
        treeElement = {
          ...treeElement,
          links: treeElement.links.map(linkId => state.tree.links.get(linkId))
            .filter(link => ['logo', 'watermark'].includes(link.linkType)).map(link => link.id),
          defLinks: false
        };
      }

      switch (payload.resourceType) {
        case 'info': {
          const data = payload.data as Info;
          return {
            ...state,
            tree: {
              ...state.tree,
              nodes: state.tree.nodes.set(payload.elementId, {
                ...treeElement,
                info: data.title,
                extendedInfo: data.body
              })
            },
            history: getUndoHistory(pastStates, state.tree, action)
          }
        }
        case 'upload': {
          const data = payload.data as Upload;

          return {
            ...state,
            tree: {
              ...state.tree,
              nodes: state.tree.nodes.set(payload.elementId, {
                ...treeElement,
                links: [...treeElement.links.filter(value => value !== payload.replaceOldResource), payload.newLinkId]
              }),
              links: state.tree.links.set(payload.newLinkId,
                {
                  id: payload.newLinkId,
                  linkType: data.subtype || 'file',
                  action: data.tempSource,
                  adopted: false,
                  label: data.filename,
                  settings: data.settings
                })
            },
            history: getUndoHistory(pastStates, state.tree, action)
          }
        }
        case 'link': {
          const data = payload.data as ResourceLink;

          return {
            ...state,
            tree: {
              ...state.tree,
              nodes: state.tree.nodes.set(payload.elementId, {
                ...treeElement,
                links: [...treeElement.links.filter(value => value !== payload.replaceOldResource), payload.newLinkId]
              }),
              links: state.tree.links.set(payload.newLinkId, {
                id: payload.newLinkId,
                linkType: 'http',
                action: data.url,
                adopted: false,
                label: data.name || data.url
              })
            },
            history: getUndoHistory(pastStates, state.tree, action)
          }
        }
      }
      return state;
    }
    case TreeActionTypes.UPDATE_RESOURCE: {
      const payload = action.payload;

      const treeElement = state.tree.nodes.get(payload.elementId);
      if (payload.resourceType === 'info') {
        const info = payload.data as Info;
        return {
          ...state,
          tree: {
            ...state.tree,
            nodes: state.tree.nodes.set(payload.elementId, {
              ...treeElement,
              info: info.title,
              extendedInfo: info.body,
              defInfo: state.activeLanguage.isDefault ? treeElement.defInfo : false,
              infoAutoTranslated: state.activeLanguage.isDefault ? treeElement.infoAutoTranslated : false
            })
          },
          history: getUndoHistory(pastStates, state.tree, action)
        }

      } else if (payload.resourceType === 'upload') {
        const data = payload.data as Upload & ResourceUpdate;

        return {
          ...state,
          tree: {
            ...state.tree,
            links: state.tree.links.set(data.id, {
              ...state.tree.links.get(data.id),
              settings: data.settings
            })
          },
          history: getUndoHistory(pastStates, state.tree, action)
        }
      } else {
        throw new Error('Only implemented for info and image uploads');
      }
    }
    case TreeActionTypes.CHANGE_RESOURCE_COLOR: {
      const payload = action.payload;

      if (!payload.oldResourceId) {
        return {
          ...state,
          tree: {
            ...state.tree,
            nodes: state.tree.nodes.set(payload.elementId, {
              ...state.tree.nodes.get(payload.elementId),
              infoColor: payload.color
            })
          },
          history: getUndoHistory(pastStates, state.tree, action)
        }
      }

      let treeElement = state.tree.nodes.get(payload.elementId);

      return {
        ...state,
        tree: {
          ...state.tree,
          nodes: state.tree.nodes.set(payload.elementId, {
            ...treeElement,
            links: [...treeElement.links.filter(value => value !== payload.oldResourceId), payload.newResourceId]
          }),
          links: state.tree.links.set(payload.newResourceId, {
            ...state.tree.links.get(payload.oldResourceId),
            color: payload.color,
            serverId: null,
            id: payload.newResourceId
          })
        },
        history: getUndoHistory(pastStates, state.tree, action)
      }
    }
    case TreeActionTypes.REMOVE_RESOURCE: {
      const payload = action.payload;
      const treeElement = state.tree.nodes.get(payload.elementId);
      if (payload.resourceType === 'info') {
        return {
          ...state,
          tree: {
            ...state.tree,
            nodes: state.tree.nodes.set(payload.elementId, {
              ...treeElement,
              info: null,
              extendedInfo: null
            })
          },
          history: getUndoHistory(pastStates, state.tree, action)
        }
      } else {
        return {
          ...state,
          tree: {
            ...state.tree,
            nodes: state.tree.nodes.set(payload.elementId, {
              ...treeElement,
              links: treeElement.links.filter(value => value !== payload.resourceId)
            })
          },
          history: getUndoHistory(pastStates, state.tree, action)
        }
      }
    }

    case TreeActionTypes.ACTIVATE_ELEMENT: {
      const payload = action.payload;
      return {
        ...state,
        activeNode: {
          id: payload.id,
          type: payload.activationType
        }
      };
    }
    case TreeActionTypes.DEACTIVATE_ELEMENT: {
      if (!action.payload || !state.activeNode ||
        (action.payload.id === state.activeNode.id && action.payload.activationType === state.activeNode.type)) {
        return {
          ...state,
          activeNode: null
        };
      } else {
        return state;
      }
    }
    case TreeActionTypes.CHANGE_LANGUAGE: {
      const activeLanguage = action.payload?.newLanguage || state.activeLanguage;
      if (action.payload?.source === 'customer') {
        return {
          ...state,
          activeLanguage: activeLanguage
        }
      } else if (!!action.payload) {
        const treeWithLanguages = <TreeWithLanguages>state.tree;
        return {
          ...state,
          tree: {
            ...state.tree,
            nodes: treeWithLanguages.translatedNodes.get(activeLanguage.id),
            links: treeWithLanguages.translatedLinks.get(activeLanguage.id)
          },
          activeLanguage: activeLanguage
        }
      } else {
        return state;
      }
    }
    case TreeActionTypes.MOVE_TREE_ELEMENT: {
      let payload = action.payload;
      const backupData = payload.backupElementData;

      let sourceElement: TreeElement = state.tree.nodes.get(payload.sourceElementId);
      let linksToAdd: Link[] = [];
      if (!sourceElement) {
        sourceElement = findTemplateElement(state.templateNode, payload.sourceElementId);
      }

      let overriddenBulletChildren = undefined;
      if (!sourceElement) {
        const isTemplate = isTemplateId(backupData.treeElement);
        sourceElement = {...backupData.treeElement,
          labels: isTemplate ? [] : backupData.treeElement.labels
        };

        if (isProcess(sourceElement)) {
          if (sourceElement.type === 'page' || sourceElement.nodes?.length > 0) {
            sourceElement.hasUnloadedChildren = true;
          }
          sourceElement.nodes = [];
        }

        linksToAdd = isTemplate ? [] : backupData.links;
        overriddenBulletChildren = backupData.bulletChildren;
      }

      if (payload.isNew) {
        payload = {
          ...payload,
          sourceElementId: payload.newIds[payload.newIds.length - 1]
        };
        const targetParent: Process = positionElement(state, payload, undefined, sourceElement.type);

        const updatedNodes = recursiveCopy(targetParent, sourceElement, state.tree.nodes, [...payload.newIds], false,
          overriddenBulletChildren)
          .set(targetParent.id, targetParent);

        const updatedSourceElement = {
          ...updatedNodes.get(payload.sourceElementId),
          hasUnloadedChildren: sourceElement.type === 'page' ? true : undefined
        } as TreeElement;

        action = {
          ...action,
          payload: {
            ...action.payload,
            newIds: isProcess(updatedSourceElement) ? [payload.sourceElementId, ...(updatedSourceElement.bullets || [])] :
              [payload.sourceElementId]
          }
        };

        return {
          ...state,
          tree: {
            ...state.tree,
            nodes: updatedNodes,
            links: state.tree.links.withMutations((map: Immutable.Map<string, Link>) => linksToAdd.forEach(link => {
              if (!map.has(link.id)) {
                map.set(link.id, link);
              }
            }))
          },
          history: getUndoHistory(pastStates, state.tree, action)
        }
      } else {
        const targetParent: Process = positionElement(state, payload, sourceElement.parentId);
        const originalSourceParent: Process = state.tree.nodes.get(sourceElement.parentId);

        let updatedSourceParent: Process;
        if (!!originalSourceParent && targetParent.id === originalSourceParent.id) {
          updatedSourceParent = targetParent;
        } else if (!!originalSourceParent) {
          updatedSourceParent = deleteElement(sourceElement, originalSourceParent);
        }

        const newItem = {
          ...sourceElement,
          parentId: targetParent.id
        };

        if (!originalSourceParent || hasChanged(targetParent, originalSourceParent)) {
          return {
            ...state,
            tree: {
              ...state.tree,
              nodes: state.tree.nodes.withMutations((map: Immutable.Map<string, TreeElement>) => {
                if (!!updatedSourceParent) {
                  map.set(updatedSourceParent.id, updatedSourceParent);
                }

                if (!!overriddenBulletChildren) {
                  overriddenBulletChildren.forEach(bullet => map.set(bullet.id, bullet));
                }

                map.set(targetParent.id, targetParent).set(newItem.id, newItem)
              }),
              links: state.tree.links.withMutations((map: Immutable.Map<string, Link>) => linksToAdd.forEach(link => {
                if (!map.has(link.id)) {
                  map.set(link.id, link);
                }
              }))
            },
            history: getUndoHistory(pastStates, state.tree, action)
          }
        } else {
          return state;
        }
      }
    }
    case TreeActionTypes.ADD_TREE_ELEMENT: {
      const payload = action.payload;
      const newParent = positionElement(state, payload, undefined, payload.newElement.type);

      const newElement = {
        ...payload.newElement,
        parentId: newParent.id,
        writeable: true
      };

      const activeNode: NodeActivation = {
        id: newElement.id,
        type: 'textEdit'
      };

      if (!newParent.hasUnloadedChildren && (!newParent.nodes || newParent.nodes.length === 0) && newElement.type !==
        'bullet' && newParent.type !== 'mainpage') {
        const parentsParent = state.tree.nodes.get(newParent.parentId) as Process;
        newParent.childSize = parentsParent && parentsParent.childSize || new Size(1, 1);
      }

      return {
        ...state,
        tree: {
          ...state.tree,
          nodes: state.tree.nodes.set(newElement.id, newElement)
            .set(newParent.id, newParent)
        },
        history: getUndoHistory(pastStates, state.tree, action),
        activeNode: activeNode
      }
    }
    case TreeActionTypes.DELETE_TREE_ELEMENT: {
      const sourceElement = state.tree.nodes.get(action.payload.id);
      const openChain = calculateOpenRows(state.tree, state.openNode, true);

      let openNode = state.openNode;
      openChain.forEach(value => {
        if (value.id === sourceElement.id || (sourceElement.type === 'bullet' && value.id === sourceElement.parentId)) {
          openNode = value.parentId;
        }
      });

      const updatedParent = deleteElement(sourceElement, state.tree.nodes.get(sourceElement.parentId));
      return {
        ...state,
        tree: {
          ...state.tree,
          nodes: state.tree.nodes.withMutations((map: Immutable.Map<string, TreeElement>) => {
            map.set(updatedParent.id, updatedParent).delete((action as DeleteTreeElement).payload.id)
          })
        },
        history: getUndoHistory(pastStates, state.tree, action),
        openNode: openNode
      }
    }
    case TreeActionTypes.UNDO_STEP: {
      const previous: HistorySnapshot = pastStates[pastStates.length - 1];

      let openNode = state.openNode;
      while (!previous.tree.nodes.has(openNode) && state.tree.nodes.has(openNode)) {
        openNode = state.tree.nodes.get(openNode).parentId;
      }

      return {
        ...state,
        tree: previous.tree,
        history: {
          pastStates: pastStates.slice(0, pastStates.length - 1),
          futureStates: [{
            action: previous.action,
            tree: state.tree
          }, ...futureStates]
        },
        openNode: openNode
      }
    }
    case TreeActionTypes.REDO_STEP: {
      const redone: HistorySnapshot = futureStates[0];

      let openNode = state.openNode;
      while (!redone.tree.nodes.has(openNode) && state.tree.nodes.has(openNode)) {
        openNode = state.tree.nodes.get(openNode).parentId;
      }

      return {
        ...state,
        tree: redone.tree,
        history: {
          pastStates: [...pastStates, {
            action: redone.action,
            tree: state.tree
          }],
          futureStates: futureStates.slice(1)
        },
        openNode: openNode
      }
    }
    case TreeActionTypes.SAVE_STEPS: {
      return {
        ...state,
        saving: true
      }
    }
    case TreeActionTypes.SAVE_STEPS_COMPLETE:
    case TreeActionTypes.SAVE_STEPS_FAILED: {
      return {
        ...state,
        saving: false
      }
    }
    case TreeActionTypes.CLEAR_HISTORY: {
      if (!!action.payload?.onlyFuture) {
        return {
          ...state,
          history: {
            ...state.history,
            futureStates: []
          }
        }
      } else {
        return {
          ...state,
          history: initialHistory
        }
      }
    }

    case TreeActionTypes.UPDATE_SERVER_IDS: {
      let activeNode = state.activeNode;
      const updatedIds = action.payload.updatedIds;
      const nodes = state.tree.nodes.withMutations((map: Immutable.Map<string, TreeElement>) => {
        if (!!updatedIds) {
          for (const key of Object.keys(updatedIds)) {
            if (!!activeNode && key === activeNode.id) {
              activeNode = {
                ...state.activeNode,
                id: String(updatedIds[key])
              };
            }
            map = map.set(key, {
              ...map.get(key),
              serverId: updatedIds[key]
            });
          }
        }
      });

      return {
        ...state,
        activeNode: activeNode,
        tree: {
          ...state.tree,
          nodes
        }
      };
    }

    case FormatActionTypes.APPLY_FORMAT: {
      const sourceElement = state.tree.nodes.get(action.payload.sourceId);
      const sourceParent = state.tree.nodes.get(sourceElement.parentId) as Process;
      const targetElement = state.tree.nodes.get(action.payload.targetId);
      const targetParent = state.tree.nodes.get(targetElement.parentId) as Process;

      if (!sourceElement || !sourceParent || !targetElement || !targetParent) {
        throw new Error('Source, target or their parents does not exist');
      }

      const isBulletUpdate = targetElement.type === 'bullet';
      const updatingSameType = (sourceElement.type === 'bullet') === (targetElement.type === 'bullet');
      let updatedTarget: Process | Bullet;

      if (updatingSameType) {
        updatedTarget = {
          ...targetElement,
          label: action.payload.restyledLabel,
          style: isBulletUpdate ? {
            ...targetElement.style,
            shape: sourceElement.style && sourceElement.style.shape || Shape.BULLET
          } : {...sourceElement.style}
        };
      } else { // Only update text, element style not relevant
        updatedTarget = {
          ...targetElement,
          label: action.payload.restyledLabel
        };
      }

      let updatedTargetParent: Process = targetParent;
      if (updatingSameType && !isBulletUpdate) {
        updatedTargetParent = {
          ...targetParent,
          childSize: sourceParent.childSize
        };
      }

      const updatedTree = {
        ...state.tree,
        nodes: state.tree.nodes.set(updatedTargetParent.id, updatedTargetParent).set(updatedTarget.id, updatedTarget)
      };

      return {
        ...state,
        tree: updatedTree,
        history: getUndoHistory(pastStates, state.tree, action),
      };
    }
    case TreeActionTypes.CREATE_LABEL:
    case TreeActionTypes.UPDATE_LABEL:
      return {
        ...state,
        tree: {
          ...state.tree,
          labels: state.tree.labels.set(action.payload.label.id, action.payload.label)
        }
      }
    case TreeActionTypes.DELETE_LABEL:
      return {
        ...state,
        tree: {
          ...state.tree,
          labels: state.tree.labels.remove(action.payload.label.id)
        }
      }
    default:
      return state;
  }
}

export const getTree = (state: TreeState) => state.tree;
export const getRoot = (tree: Tree) => tree && tree.root && tree.nodes.get(tree.root) as Process || null;
export const getLinks = (tree: Tree) => tree && tree.links;
export const getOpenRows = (tree: Tree, openNode: string) => calculateOpenRows(tree, openNode);
export const hasOpenUnsavedNodes = (tree: Tree, openRows: Process[], openNode: string) => [...openRows,
  tree.nodes.get(openNode)].some(row => !row.serverId);

export const getNodeActivation = (state: TreeState): NodeActivation => !!state.activeNode &&
!!state.tree.nodes.get(state.activeNode.id) ? state.activeNode : {
  type: null,
  id: ''
};

export const isLoading = (state: TreeState) => state.loading;
export const getHistory = (state: TreeState) => state.history;
export const getActiveLanguage = (state: TreeState) => state.activeLanguage;
export const hasUndo = (history: History) => history.pastStates.length > 0;
export const getHistoryActions = (history: History) => history.pastStates.map(pastState => pastState.action);

export const getHistoryLabelActions = (actions: Action[]): UpdateTreeElementLabels[] => actions.filter(action => action.type === TreeActionTypes.UPDATE_TREE_ELEMENT_LABELS).map(action => action as UpdateTreeElementLabels);
export const hasRedo = (history: History) => history.futureStates.length > 0;
export const getPath = (tree: Tree) => tree.path.concat(tree.localPath);
export const getNodes = (tree: Tree) => tree.nodes;

export const getOpenId = (tree: TreeState) => tree.openNode;
export const getActiveInheritedDetails = (tree: Tree, nodeActivation: NodeActivation): InheritedDetails => {
  return getInheritedDetails(nodeActivation.id)(tree);
};

export const getInheritedDetails = (id: string) => (tree: Tree): InheritedDetails => {
  let permissions: Permissions = null;
  let owner: string = null;
  if (tree.nodes.has(id)) {
    let parentId = tree.nodes.get(id).parentId;
    do {
      const parentNode = tree.nodes.get(parentId);
      if (parentNode) {
        permissions = permissions || parentNode.permissions;
        owner = owner || parentNode.owner;
        parentId = parentNode.parentId;
      } else {
        parentId = null;
      }
    } while (parentId && (!permissions || !owner));
  }

  return {
    owner: owner,
    permissions: permissions
  }
};

const imageSorter = (a: BaseImageSettings, b: BaseImageSettings): number => {
  return (+a.inherited) - (+b.inherited);
};

export const getAllWatermarkSettings = (root: Process, links: Immutable.Map<string, Link>) => {
  return (root && root.links || []).map(linkId => links.get(linkId)).filter(link => link?.linkType === 'watermark')
    .map(watermark => {
      return {
        link: watermark,
        source: watermark.action,
        filename: watermark.label,
        inherited: watermark.adopted,
        maintainAspectRatio: watermark.settings.maintainAspectRatio === 'true',
        alpha: Number(watermark.settings.alpha) || WATERMARK_DEFAULT_OPACITY,
        ratio: Number(watermark.settings.ratio)
      } as WatermarkSettings
    }).sort(imageSorter);
};

export const getAllLogoSettings = (root: Process, links: Immutable.Map<string, Link>) => {
  return (root && root.links || []).map(linkId => links.get(linkId)).filter(link => link?.linkType === 'logo')
    .map(logo => {
      return {
        link: logo,
        source: logo.action,
        filename: logo.label,
        inherited: logo.adopted,
        maintainAspectRatio: logo.settings.maintainAspectRatio === 'true',
        width: Number(logo.settings.width),
        height: Number(logo.settings.height),
        position: logo.settings.position as Position,
        scale: logo.settings.scale === 'true'
      } as LogoSettings
    }).sort(imageSorter);
};

export const getLogoSettings = (logoSettings: LogoSettings[]) => {
  return logoSettings && logoSettings[0] || null;
};

export const getWatermarkSettings = (waterMarkSettings: WatermarkSettings[]) => {
  return waterMarkSettings && waterMarkSettings[0] || null;
};

export interface LoadEffect {
  nodeId: string;
  action: 'nothing' | 'openNode' | 'loadChildren' | 'fullLoad';
  treeElement: TreeElement;
}

export function getLoadEffect(nodeId, treeState: TreeState, user: User) {
  let forceReload = false;

  const tree = treeState.tree;
  if (!nodeId) {
    forceReload = true;
    if (tree.source === 'customer') {
      if (treeState.openNode) {
        nodeId = treeState.openNode
      } else if (tree.root) {
        nodeId = tree.root;
      }
    } else if (!user) {
      return null;
    } else {
      nodeId = String(user.startId) || String(user.accessId);
    }
  }

  const treeElement: TreeElement = tree.nodes.get(nodeId);
  const loadEffect: LoadEffect = {
    nodeId,
    treeElement,
    action: 'fullLoad'
  };

  if (!forceReload && treeElement && tree.nodes.get(tree.root).type === 'page' && treeElement.type !==
    'mainpage') {
    if (isProcess(treeElement) && treeElement.hasUnloadedChildren) {
      loadEffect.action = 'loadChildren';
    } else {
      loadEffect.action = 'nothing'
    }
  }
  return loadEffect;
}
