import {HttpClient, HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatInput} from '@angular/material/input';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {combineLatest, defer, iif, of, Subscription} from 'rxjs';
import {catchError, debounceTime, map, startWith, switchMap, tap} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SearchElement} from '../../../shared/model/search.element';
import {ReportService} from '../../../shared/services/report.service';

interface SearchResult {
  total: number;
  elem: SearchElement[];
}

@Component({
  selector: 'pm-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements AfterViewInit, OnInit, AfterViewInit, OnDestroy {
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatInput) inputField: MatInput;

  folded = false;
  searchTextControl: FormControl = new FormControl('');
  searchTypeControl: FormControl = new FormControl('text');
  searchResultsSubscription: Subscription;
  loading = false;
  displayedColumns = ['label', 'path'];
  dataSource = new MatTableDataSource<SearchElement>();
  activeSearchElement: any = null;

  constructor(private userService: UserService, private http: HttpClient, private reportService: ReportService,
    private snackBar: MatSnackBar, private translate: TranslateService) {
    this.dataSource.sortingDataAccessor = (data, sortHeaderId) => data[sortHeaderId]?.toLocaleLowerCase() ?? '';
  }

  ngOnInit() {
    this.searchResultsSubscription = combineLatest([this.searchTextControl.valueChanges.pipe(startWith('')),
      this.searchTypeControl.valueChanges.pipe(startWith('text'))])
      .pipe(map(([searchString, type]) => [this.removeShortWords(searchString, 3), type]), debounceTime(250),
        tap(() => this.loading = true), switchMap(([searchString, type]) => {
          const url = environment.api + this.userService.domain + '/search';
          return iif(() => !!searchString, defer(() => this.http.post<SearchResult>(url, {
            searchString: searchString,
            searchType: type
          })).pipe(catchError(err => {
            if (err instanceof HttpErrorResponse && err.status === HttpStatusCode.Forbidden) {
              this.snackBar.open(this.translate.instant('dialog.search.error.too-many-results'));
            }
            return of({elem: []})
          }), map(searchResult => this.reportService.translateBackupElement(searchResult.elem))), of([]));
        }), tap(() => this.loading = false)).subscribe(searchElements => {
        this.dataSource.data = searchElements || []
      });
  }

  ngOnDestroy() {
    this.searchResultsSubscription.unsubscribe();
  }

  ngAfterViewInit() {
    window.setTimeout(() => this.inputField.focus());
    this.dataSource.sort = this.sort;
  }

  navigateToNode(searchElement: SearchElement) {
    this.activeSearchElement = searchElement.id;
    this.reportService.navigate(searchElement);
  }

  onFoldedChange(folded: boolean) {
    this.folded = folded;
  }

  private removeShortWords(sentence: string, minLength: number): string {
    return (sentence && sentence.trim().split(/\s+/) || ['']).filter(word => word.length >= minLength).join(' ');
  }
}
