import {Component, Inject, OnInit, Optional} from '@angular/core';
import {UntypedFormBuilder, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';

import {TreeElement} from '../../shared/model/treeelement';
import {TemplatesService} from '../../shared/services/templates.service';
import {PublishErrorData, PublishErrorDialogComponent} from './publish-error-dialog/publish-error-dialog.component';
import {
  PopupDialogComponent,
  PopupDialogData
} from "../../shared/components/dialogs/popup-dialog/popup-dialog.component";

@Component({
  selector: 'pm-publish-template-dialog',
  templateUrl: './publish-template-dialog.component.html',
  styleUrls: ['./publish-template-dialog.component.css']
})
export class PublishTemplateDialogComponent implements OnInit {
  templateNames: string[] = [];

  templateForm = this.fb.group({
    template: ['', [Validators.required]],
    descriptions: this.fb.group({
      da: ['', [Validators.required]],
      en: ['', [Validators.required]]
    })
  })

  constructor(private templatesService: TemplatesService, private fb: UntypedFormBuilder,
    @Optional() private dialogRef: MatDialogRef<PublishTemplateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: TreeElement, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    this.templatesService.listTemplates()
      .subscribe(templates => this.templateNames = templates.map(template => template.id));
  }

  submit() {
    this.templatesService.createVersion(this.data.id, this.templateForm.value.template,
      this.templateForm.value.descriptions).subscribe(() => {
      this.dialogRef.close();
    }, (e) => {
      this.dialogRef.close();
        switch(e.error?.errorCode) {
          case 'missing translations':
            this.translationError(e.error);
            break;
          case 'language config':
            this.generalError('dialog.template-publish.error.language-config');
            break;
          default:
            this.generalError('dialog.template-publish.error');
            break;
        }
    });
  }

  private translationError(error: PublishErrorData) {
    this.dialog.open<PublishErrorDialogComponent, PublishErrorData>(PublishErrorDialogComponent, {
      position: {
        top: '95px',
        left: '0px'
      },
      data: error,
      hasBackdrop: false
    });
  }

  private generalError(body: string) {
    this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
      data: {
        body
      }
    });
  }
}
