import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UserService} from '@process-manager/pm-library';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {NodeType} from '../../../shared/model/treeelement';
import {ReportService} from '../../../shared/services/report.service';

interface OwnerWithElements {
  [key: string]: SearchElement[];
}

interface SearchElement {
  label: string;
  id: number;
  type: NodeType;
  pos: number;
  path: string;
  parent: number;
  parentPage: number;
}

interface DataSourceElement extends SearchElement {
  owner: string;
  ownerRowSpan: number;
}

@Component({
  selector: 'pm-owner-list',
  templateUrl: './owner-list.component.html',
  styleUrls: ['./owner-list.component.css']
})
export class OwnerListComponent implements OnInit, OnDestroy {
  folded = false;
  searchResultsSubscription: Subscription;
  loading = true;
  displayedColumns = ['owner', 'label', 'path'];
  dataSource = new MatTableDataSource<DataSourceElement>();
  activeSearchElement: any = null;

  constructor(private userService: UserService, private http: HttpClient, private reportService: ReportService) {
  }

  ngOnInit() {
    const url = environment.api + this.userService.domain + '/owners';
    const compareFn = (a, b) => (a?.label || '').localeCompare(b?.label || '', undefined, {numeric: true});
    this.searchResultsSubscription =
      this.http.get<OwnerWithElements>(url).pipe(
        finalize(() => this.loading = false)).subscribe(owner => {
          const dataSource: DataSourceElement[] = [];
          Object.keys(owner).sort().forEach(key => {
            const searchElements = this.reportService.translateBackupElement(owner[key].sort(compareFn));
            let ownerRowSpan = searchElements.length;
            searchElements.forEach((element) => {
              dataSource.push({...element,
                owner: key,
                ownerRowSpan,
              })
              ownerRowSpan = 0;
            })
          });
          this.dataSource.data = dataSource;
      });
  }

  ngOnDestroy() {
    this.searchResultsSubscription.unsubscribe();
  }

  navigateToNode(searchElement: DataSourceElement) {
    this.activeSearchElement = searchElement.id;
    this.reportService.navigate(searchElement);
  }

  getRowSpan(element: DataSourceElement) {
      return element.ownerRowSpan;
  }
}
