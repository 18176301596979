import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Store} from '@ngrx/store';
import {tap, withLatestFrom} from 'rxjs/operators';
import {
  LabelSettingsChange,
  MarkTranslationChange,
  NotSearchableChange,
  ViewOptionActionTypes
} from '../actions/view-options.actions';
import {AppState, getAuthDomain} from '../reducers/';
import {HistoryLoaderService} from "../../shared/services/history-loader.service";

// noinspection JSUnusedGlobalSymbols
@Injectable()
export class ViewOptionsEffects {
  public static readonly MARK_TRANSLATION_LOCALSTORAGE_POSTFIX = '_mark_translation_value';
  public static readonly LABEL_SETTINGS_LOCALSTORAGE_POSTFIX = '_label_settings_value';
  public static readonly SHOW_UNSEARCHABLE_LOCALSTORAGE_POSTFIX = '_show_unsearchable_value';
  markTranslationChange = createEffect(
    () => this.actions$.pipe(ofType<MarkTranslationChange>(ViewOptionActionTypes.MARK_TRANSLATION_CHANGE),
      withLatestFrom(this.store$.select(getAuthDomain)), tap(([action, domain]) => {
        if (!!action.payload.store) {
          localStorage.setItem(ViewOptionsEffects.getMarkTranslationStorageId(domain), action.payload.type)
        }
      })), {dispatch: false});
  labelDisplayChange = createEffect(
    () => this.actions$.pipe(ofType<LabelSettingsChange>(ViewOptionActionTypes.LABEL_SETTINGS_CHANGE),
      withLatestFrom(this.store$.select(getAuthDomain)), tap(([action, domain]) => {
        if (!!action.payload.store) {
          localStorage.setItem(ViewOptionsEffects.getLabelDisplayStorageId(domain),
            JSON.stringify(action.payload.settings))
        }
      })), {dispatch: false});

  ShowUnsearchableChange = createEffect(
    () => this.actions$.pipe(
      ofType<NotSearchableChange>(ViewOptionActionTypes.NOT_SEARCHABLE_CHANGE),
      withLatestFrom(this.store$.select(getAuthDomain)), tap(([action, domain]) => {
        if (!!action.payload.store) {
          localStorage.setItem(ViewOptionsEffects.getShowUnsearchableStorageId(domain), JSON.stringify(action.payload.show))
        }
        this.historyLoadService.forceReload();
      })), {dispatch: false})

  constructor(private actions$: Actions, private store$: Store<AppState>, private historyLoadService: HistoryLoaderService) {
  }

  public static getMarkTranslationStorageId(domain: string) {
    return domain + ViewOptionsEffects.MARK_TRANSLATION_LOCALSTORAGE_POSTFIX;
  }

  public static getLabelDisplayStorageId(domain: string) {
    return domain + ViewOptionsEffects.LABEL_SETTINGS_LOCALSTORAGE_POSTFIX;
  }

  public static getShowUnsearchableStorageId(domain: string) {
    return domain + ViewOptionsEffects.SHOW_UNSEARCHABLE_LOCALSTORAGE_POSTFIX;
  }

}
