import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  HostListener,
  NgZone,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatIconRegistry} from '@angular/material/icon';
import {MatSidenav} from '@angular/material/sidenav';
import {DomSanitizer} from '@angular/platform-browser';
import {ActivatedRoute} from '@angular/router';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {ForceLogbookEntryLevel, Language, UserService} from '@process-manager/pm-library';
import {User} from '@process-manager/pm-library/lib/model/user';
import {combineLatest} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import {AppConfig} from '../app.config';

import {isTemplateId} from '../shared/model/template-id';
import {OfflineTreeService} from '../shared/services/offline-tree.service';
import {LanguageMap} from '../shared/services/template-language-mapping.service';
import {ModalTrackerService} from '../shared/trumbowyg.directive';
import {Offline} from '../state-management/actions/auth.actions';
import {AddLogbookEntry} from '../state-management/actions/logbook-saving.actions';
import {LoadTemplateAction} from '../state-management/actions/templates.actions';
import {ChangeLanguage, DeactivateElement, LoadTree} from '../state-management/actions/tree.actions';
import {
  AppState,
  getAuthDomain,
  getAuthIsAuthorOrAdmin,
  getAuthPasswordUpdatable,
  getAuthUser,
  getAuthUserIsPublic,
  getLogbookSavedSinceLogging,
  getRenderedOpenRows,
  getTree,
  getTreeActiveLanguage,
  getTreeDefaultLanguage,
  getTreeHasUndo,
  getTreeNodeActivation,
  getTreeSource
} from '../state-management/reducers/';
import {
  TemplateDialogData,
  TemplateLanguageDialogComponent
} from './copy-template-dialog/template-language-dialog.component';
import {LabelAdminDialogComponent} from './label-admin-dialog/label-admin-dialog.component';
import {LanguageAdminDialogComponent} from './language-admin-dialog/language-admin-dialog.component';
import {LogbookDialogComponent} from './logbook-dialog/logbook-dialog.component';
import {PasswordChangeDialogComponent} from './password-change-dialog/password-change-dialog.component';
import {ProcessTreeComponent} from './process-tree/process-tree.component';
import {LinkCheckComponent} from './reports/link-check/link-check.component';
import {OwnerListComponent} from './reports/owners/owner-list.component';
import {PermissionListComponent} from './reports/permissions/permission-list.component';
import {StatisticsDialogComponent} from './reports/statistics-dialog/statistics-dialog.component';
import {SubscriptionDialogComponent} from './subscription-dialog/subscription-dialog.component';
import {SubscriptionService} from './subscription-dialog/subscription.service';
import {TemplateAdminDialogComponent} from './template-admin-dialog/template-admin-dialog.component';
import {HELP_URL_TEMPLATE} from './toolbars';
import {LogoDialogComponent} from './toolbars/logo-dialog/logo-dialog.component';
import {UserAdminDialogComponent} from './user-admin-dialog/user-admin-dialog.component';
import {PopupDialogComponent} from "../shared/components/dialogs/popup-dialog/popup-dialog.component";

const KNOWN_UI_LANGUAGES = ['DA', 'EN', 'IT'];

@Component({
  selector: 'pm-mainpage',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSidenav, {static: true}) matSideNav: MatSidenav;

  @ViewChild(ProcessTreeComponent) processTree: ProcessTreeComponent;

  tree$ = this.store$.select(getTree);
  domain$ = this.store$.select(getAuthDomain);
  openRows$ = this.store$.select(getRenderedOpenRows);
  hasUndo: boolean;
  hasUndoSubscription = this.store$.select(getTreeHasUndo).subscribe(hasUndo => this.hasUndo = hasUndo);
  needsLogbookEntry: ForceLogbookEntryLevel;
  needsLogbookEntrySubscription = this.store$.select(getLogbookSavedSinceLogging).subscribe(savedSinceLogging => {
    this.needsLogbookEntry = savedSinceLogging && this.userService.getSiteSettings().showLogoutLogbook || 'none';
  });

  nodeActivation$ = this.store$.select(getTreeNodeActivation);
  currentLanguage = this.store$.select(getTreeActiveLanguage);

  isAuthor$ = this.store$.select(getAuthIsAuthorOrAdmin);
  isDefaultLang$ = this.store$.select(getTreeDefaultLanguage);

  showImageOption$ = combineLatest([this.isAuthor$, this.isDefaultLang$])
    .pipe(map(([isAuthor, isDefaultLang]) => isAuthor && isDefaultLang));

  user$ = this.store$.select(getAuthUser);
  userIsPublic = this.store$.select(getAuthUserIsPublic);
  passwordUpdatable = this.store$.select(getAuthPasswordUpdatable);
  canChangePassword = this.passwordUpdatable.pipe(
    map((updatable) => updatable && this.userService?.getSiteSettings()?.localUserAdministration));

  isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window['MSStream'];
  isTemplate$ = this.store$.select(getTreeSource).pipe(map(source => isTemplateId(source)));
  // We want an update if either of them changes
  private routeSubscription = combineLatest([this.route.params, this.route.queryParams])
    .subscribe(([params, queryParams]) => {
      this.ngZone.run(() => {
        const templateId = params['templateName'];
        const templateVersion = params['templateVersion'];
        const id = params['nodeid'];

        if (!!templateId && !!templateVersion) {
          this.store$.dispatch(new LoadTemplateAction({
            templateId: {
              templateId,
              templateVersion
            },
            nodeId: id
          }));
        } else {
          const highlight = queryParams['highlight'] ||
            (this.appConfig.highlightNext && this.userService.user && this.userService.user.startId !== id);
          this.appConfig.highlightNext = false;
          if (highlight) {
            window.setTimeout(() => this.store$.dispatch(new DeactivateElement({
              id,
              activationType: 'highlight'
            })), 8000);
          }
          this.store$.dispatch(new LoadTree({
            id: params['nodeid'],
            highlight: highlight
          }));
        }
      });
    });
  detectOffline: boolean;
  isOffline: boolean;

  constructor(mdIconRegistry: MatIconRegistry, domSanitizer: DomSanitizer, private dialog: MatDialog,
    private route: ActivatedRoute, private userService: UserService, private store$: Store<AppState>,
    private appConfig: AppConfig, private ngZone: NgZone, private translate: TranslateService,
    private subscriptionService: SubscriptionService, private offlineTreeService: OfflineTreeService,
    private modalTrackerService: ModalTrackerService) {
    mdIconRegistry.addSvgIcon('proman-logo',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/ProcessManagerLogo.svg'));
    mdIconRegistry.addSvgIcon('proman-resize-handle',
      domSanitizer.bypassSecurityTrustResourceUrl('assets/resize-handle.svg'));
  }

  get isVendor(): boolean {
    return this.userService.getSiteSettings()?.vendorFeatures || false;
  }

  get userAdminTitleKey(): string {
    return this.userService.getSiteSettings().localUserAdministration ? 'dialog.user-admin.title' :
      'dialog.group-admin.title';
  }

  get hasSiteMap(): boolean {
    return this.userService.getSiteSettings()?.showSiteMap || false;
  }

  get buyNowLink(): string {
    return this.subscriptionService.buyNowUrl;
  }

  get isTrial() {
    return this.userService.getSiteSettings()?.subscriptionState === 'trial';
  }

  get isQuoteTrial() {
    return this.userService.getSiteSettings()?.subscriptionState === 'quote';
  }

  get isPurchase() {
    return this.userService.getSiteSettings()?.subscriptionState === 'purchase';
  }

  get isModern() {
    return (this.userService.getSiteSettings()?.subscriptionState ?? 'legacy') !== 'legacy';
  }

  get offlineEnabled() {
    return this.userService.getSiteSettings()?.offlineEnabled || false;
  }

  @HostListener('window:beforeunload', ['$event']) checkSave($event) {
    if (!this.hasUndo && this.needsLogbookEntry !== 'none') {
      setTimeout(() => {
        const force = this.needsLogbookEntry === 'strict';
        this.dialog.open(PopupDialogComponent, {
          data: {
            title: 'dialog.logout-log-warning.title',
            body: force ? 'dialog.logout-log-warning.content.force' : 'dialog.logout-log-warning.content',
            actionLabel: force ? undefined : 'dialog.logout-log-warning.content.skip'
          }
        }).afterClosed().subscribe(result => {
          switch (result) {
            case 'ok':
              this.dialog.open(LogbookDialogComponent, {
                width: '80%'
              });
              break;
            case 'action':
              this.store$.dispatch(new AddLogbookEntry());
              break;
          }
        });
      });
    }

    if (this.hasUndo || this.needsLogbookEntry !== 'none') {
      $event.preventDefault();
      $event.returnValue = '';
    }
  }

  ngAfterViewInit(): void {
    window.scrollTo(0, 1);
  }

  ngOnInit(): void {
    this.detectOffline = localStorage.getItem('detectOffline') !== 'false';
    this.offlineTreeService.isOnline().subscribe(isOnline => this.isOffline = !isOnline);
  }

  ngOnDestroy(): void {
    this.routeSubscription.unsubscribe();
    this.hasUndoSubscription.unsubscribe();
    this.needsLogbookEntrySubscription.unsubscribe();
  }

  menuClicked(): void {
    this.matSideNav.toggle();
  }

  deactivate(): void {
    this.matSideNav.toggle(false);
    this.processTree.activeTemplateNode$.next(null);
    this.store$.dispatch(new DeactivateElement());
  }

  getUserFullName(user: User) {
    return user && [user.firstName, user.lastName].join(' ');
  }

  userAdminClick() {
    this.dialog.open(UserAdminDialogComponent, {
      maxWidth: '90vw',
      disableClose: true
    });
  }

  get online() {
    return this.offlineTreeService.isOnline();
  }

  isUserAdmin(user: User) {
    const siteSettings = this.userService.getSiteSettings();
    return this.isAdmin(user) && (siteSettings.localGroupAdministration || siteSettings.localUserAdministration);
  }

  isAdmin(user: User) {
    return user && user.roles.includes('admin');
  }

  languageAdminClick() {
    this.dialog.open(LanguageAdminDialogComponent).afterClosed()
      .subscribe(() => this.store$.dispatch(new ChangeLanguage()));
  }

  labelAdminClick() {
    this.dialog.open(LabelAdminDialogComponent);
  }

  templateAdminClick() {
    this.dialog.open(TemplateAdminDialogComponent);
  }

  subscriptionAdminClick() {
    this.dialog.open(SubscriptionDialogComponent);
  }

  changePasswordClick() {
    this.dialog.open(PasswordChangeDialogComponent, {data: {username: this.userService.user.username}});
  }

  onEditLogoClick() {
    this.dialog.open(LogoDialogComponent);
  }

  helpUrl(language: Language): string {
    let uiLang = (language?.interfaceLanguage || this.translate.getBrowserLang()).toUpperCase()
      .split('_')[0];

    if (!KNOWN_UI_LANGUAGES.includes(uiLang)) {
      uiLang = 'EN';
    }

    return HELP_URL_TEMPLATE(uiLang);
  }

  ownerReportClick() {
    this.dialog.open(OwnerListComponent, {
      position: {
        top: '95px',
        left: '0px'
      },
      autoFocus: false,
      hasBackdrop: false
    });
    this.matSideNav.toggle(false);
  }

  permissionReportClick() {
    this.dialog.open(PermissionListComponent, {
      position: {
        top: '95px',
        left: '0px'
      },
      autoFocus: false,
      hasBackdrop: false
    });
    this.matSideNav.toggle(false);
  }

  linkCheckClick() {
    this.dialog.open(LinkCheckComponent, {
      position: {
        top: '95px',
        left: '0px'
      },
      autoFocus: false,
      hasBackdrop: false
    });
    this.matSideNav.toggle(false);
  }

  onEditMappings() {
    this.dialog.open<TemplateLanguageDialogComponent, TemplateDialogData, LanguageMap | null>(
      TemplateLanguageDialogComponent, {
        data: {
          type: 'edit'
        }
      });
  }

  openSiteMap() {
    return this.userService.refresh().subscribe(() => {
      window.open(environment.api + this.userService.domain + '/sitemap');
    })
  }

  openStatistics() {
    this.dialog.open(StatisticsDialogComponent);
  }

  detectChanged() {
    localStorage.setItem('detectOffline', this.detectOffline ? 'true' : 'false');
  }

  offlineChanged() {
    if (this.isOffline) {
      this.store$.dispatch(new Offline());
    } else {
      document.location.reload();
    }
  }
}
