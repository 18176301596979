import {animate, style, transition, trigger} from '@angular/animations';
import {Component, EventEmitter, Input, OnDestroy, Output} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Action, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {map, take, withLatestFrom} from 'rxjs/operators';
import * as screenfull from 'screenfull';
import {v1} from 'uuid';
import {AppConfig} from '../../app.config';
import {
  PopupDialogComponent,
  PopupDialogData
} from '../../shared/components/dialogs/popup-dialog/popup-dialog.component';
import {Process} from '../../shared/model/process';
import {Shape} from '../../shared/model/shape';
import {Style} from '../../shared/model/style';
import {isTemplateId} from '../../shared/model/template-id';
import {NodeType} from '../../shared/model/treeelement';
import {NavigationService} from '../../shared/services/navigation.service';
import {RequestLogout} from '../../state-management/actions/auth.actions';
import {ActivateFormatting, DeactivateFormatting} from '../../state-management/actions/formatting.actions';
import {ExitTemplateAction, ToggleTemplatesAction} from '../../state-management/actions/templates.actions';
import {
  AddTreeElement,
  ChangeLanguage,
  ClearHistory,
  LoadTree,
  RedoStep,
  SaveSteps,
  UndoStep
} from '../../state-management/actions/tree.actions';
import {
  LabelSettingsChange,
  MarkTranslationChange,
  NotSearchableChange
} from '../../state-management/actions/view-options.actions';
import {
  AppState,
  getAuthDomain,
  getAuthIsAuthorOrAdmin,
  getAuthUserIsOnline,
  getAuthUserIsPublic,
  getCurrentTreeLanguages,
  getFormattingActive,
  getLabelsSettings,
  getShowUnsearchable,
  getTemplatesEnabled,
  getTemplatesShowing,
  getTranslationHighlight,
  getTreeActiveLanguage,
  getTreeHasOpenUnsavedNodes,
  getTreeHasRedo,
  getTreeHasUndo,
  getTreeHasUndoNotTextEditing,
  getTreeLoading,
  getTreePath,
  getTreeRoot,
  getTreeSaving,
  getTreeSource,
  HighlightSelectionType,
  selectFavoritesAsList
} from '../../state-management/reducers/';
import {LabelSettings} from '../../state-management/reducers/view-options.reducer';
import {ConfirmDialogComponent} from '../confirm-navigation/confirm-dialog.component';
import {LogbookDialogComponent} from '../logbook-dialog/logbook-dialog.component';
import {AboutDialogComponent} from './about-dialog/about-dialog.component';
import {LanguageChange} from './language-selector';
import {SearchComponent} from './search/search.component';

const DEFAULT_PROCESS_STYLE: Partial<Style> = {
  bgColor: '#006bab',
  lineColor: '#006bab',
  shape: Shape.RIGHT_ARROW
};

const DEFAULT_PAGE_STYLE: Partial<Style> = {
  bgColor: '#ffffff',
  lineColor: '#ffffff',
  shape: Shape.NONE
};

export const HELP_URL_TEMPLATE = (uiLang) => `https://s3.eu-central-1.amazonaws.com/dk.process-manager.sites.help/user-manual-html-${uiLang}.pdf`;

@Component({
  selector: 'pm-toolbars',
  templateUrl: './toolbars.component.html',
  styleUrls: ['./toolbars.component.css'],
  animations: [trigger('spinner', [transition(':enter', [style({
    opacity: 0
  }), animate('200ms', style({
    opacity: 1
  }))]), transition(':leave', [animate('200ms', style({
    opacity: 0
  }))])])]
})
export class ToolbarsComponent implements OnDestroy {
  @Output() menuClicked = new EventEmitter<void>();
  @Input() openId: number;

  treeSource$ = this.store$.select(getTreeSource);
  isTemplateTree$ = this.treeSource$.pipe(map(treeSource => isTemplateId(treeSource)));
  showUnsearchable$ = this.store$.select(getShowUnsearchable);
  languages$ = this.store$.select(getCurrentTreeLanguages);
  currentLanguage = this.store$.select(getTreeActiveLanguage);

  path$ = this.store$.select(getTreePath);
  domain$ = this.store$.select(getAuthDomain);
  favorites$ = this.store$.select(selectFavoritesAsList);
  isAuthor$ = this.store$.select(getAuthIsAuthorOrAdmin);
  isOnline$ = this.store$.select(getAuthUserIsOnline);
  isLoading$ = this.store$.select(getTreeLoading);
  isSaving$ = this.store$.select(getTreeSaving);
  hasUndo$ = this.store$.select(getTreeHasUndo);
  hasUndoNotTextEditing$ = this.store$.select(getTreeHasUndoNotTextEditing);
  hasRedo$ = this.store$.select(getTreeHasRedo);
  hasOpenUnsavedNodes$ = this.store$.select(getTreeHasOpenUnsavedNodes);
  labelsView$ = this.store$.select(getLabelsSettings);
  translationHighlight$ = this.store$.select(getTranslationHighlight);
  templatesEnabled$ = this.store$.select(getTemplatesEnabled);
  templatesShowing$ = this.store$.select(getTemplatesShowing);

  formattingActive: boolean;
  formattingActiveSubscription = this.store$.select(getFormattingActive)
    .subscribe(active => this.formattingActive = active);

  isPublic: boolean;
  isPublicSubscription = this.store$.select(getAuthUserIsPublic).subscribe(isPublic => this.isPublic = isPublic);

  root: Process;
  rootSubscription = this.store$.select(getTreeRoot).subscribe(root => this.root = root);

  constructor(private store$: Store<AppState>, private navigationService: NavigationService, private dialog: MatDialog,
    private translate: TranslateService, private appConfig: AppConfig) {
  }

  get canFullscreen(): boolean {
    return !!screenfull && screenfull.enabled;
  }

  get newProcessPageString(): string {
    if (!!this.root && this.root.type === 'mainpage') {
      return 'menu.toolbar.new-page';
    } else {
      return 'menu.toolbar.new-process';
    }
  }

  onReloadClicked(): void {
    this.doConditionalAction(new LoadTree());
  }

  onFavoriteClicked(id: string) {
    this.appConfig.highlightNext = true;
    this.navigationService.navigateToNode(id, this.isPublic);
  }

  onFullscreen() {
    if (!!screenfull && screenfull.enabled) {
      screenfull.toggle();
    }
  }

  onLogout(): void {
    // TODO: Remove check from effects and add to doConditional.
    this.store$.dispatch(new RequestLogout({checkChanges: true}));
  }

  highlightChange(highlightType: HighlightSelectionType) {
    this.store$.dispatch(new MarkTranslationChange({
      type: highlightType,
      store: true
    }));
  }

  showUnsearchableChange(show: boolean) {
    this.store$.dispatch(new NotSearchableChange({
      show,
      store: true
    }));
  }

  labelsViewChange($event: LabelSettings) {
    if (!!$event) {
      this.store$.dispatch(new LabelSettingsChange({
        settings: $event,
        store: true
      }));
    }
  }

  ngOnDestroy(): void {
    this.isPublicSubscription.unsubscribe();
    this.rootSubscription.unsubscribe();
    this.formattingActiveSubscription.unsubscribe();
  }

  undo(): void {
    setTimeout(() => this.store$.dispatch(new UndoStep()));
  }

  redo(): void {
    setTimeout(() => this.store$.dispatch(new RedoStep()));
  }

  save(): void {
    this.store$.dispatch(new SaveSteps());
  }

  onLogbookClick() {
    this.dialog.open(LogbookDialogComponent, {
      width: '80%'
    });
  }

  onPasteClick() {
    this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
      data: {
        title: '',
        body: 'menu.toolbar.paste.click'
      }
    })
  }

  selectLanguage(languageChange: LanguageChange): void {
    this.treeSource$.pipe(take(1)).subscribe(source => {
      this.doConditionalAction(new ChangeLanguage({
        ...languageChange,
        doReload: true,
        source
      }));
    });
  }

  showSearchPanel() {
    this.dialog.open(SearchComponent, {
      width: '700px',
      maxHeight: '600px',
      position: {
        top: '95px',
        left: '0px'
      },
      autoFocus: false,
      hasBackdrop: false
    });
  }

  onMenuClicked() {
    this.menuClicked.emit();
  }

  showAbout() {
    this.dialog.open(AboutDialogComponent);
  }

  createClicked() {
    this.translate.get('element.new.text').subscribe(text => {
      const id = v1();
      const url = this.navigationService.getNavigationUrl(id);

      let newElementText: string = text;
      let newElementType: NodeType;
      let newElementStyle: Style;

      if (this.root.type === 'mainpage') {
        newElementText = '<i>' + text + '</i>';
        newElementType = 'page';
        newElementStyle = {
          ...DEFAULT_PAGE_STYLE,
          id: v1()
        } as Style;
      } else {
        newElementType = 'process';
        newElementStyle = {
          ...DEFAULT_PROCESS_STYLE,
          id: v1()
        } as Style;
      }

      const newElement = {
        id: id,
        label: newElementText,
        url: url,
        links: [],
        type: newElementType,
        style: newElementStyle,
        childSize: this.root.childSize
      };

      this.store$.dispatch(new AddTreeElement({
        sourceElementId: id,
        targetElementId: this.root.id,
        relation: 'under',
        forceBullet: false,
        newElement: newElement
      }));
    });
  }

  formatClicked() {
    if (this.formattingActive) {
      this.store$.dispatch(new DeactivateFormatting());
    } else {
      this.store$.dispatch(new ActivateFormatting());
    }
  }

  toggleTemplates() {
    this.store$.dispatch(new ToggleTemplatesAction());
  }

  exitTemplate() {
    this.store$.dispatch(new ExitTemplateAction())
  }

  private doConditionalAction(action: Action): void {
    this.hasUndo$.pipe(withLatestFrom(this.hasOpenUnsavedNodes$), take(1)).subscribe(([hasUndo, openUnsaved]) => {
      if (hasUndo) {
        this.showWarningDialog(action, openUnsaved);
      } else {
        this.store$.dispatch(action);
      }
    });
  }

  private showWarningDialog(action: Action, hideSaveButton: boolean = false) {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        hideSaveButton: hideSaveButton,
        title: 'dialog.confirm-navigation.title',
        body: 'dialog.confirm-navigation.content'
      }
    }).afterClosed().subscribe(result => {
      switch (result) {
        case 'continue':
          this.store$.dispatch(new SaveSteps({nextAction: action}));
          break;
        case 'drop':
          this.store$.dispatch(new ClearHistory());
          this.store$.dispatch(action);
          break;
        default:
          break;
      }
    });
  }
}
