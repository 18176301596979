import {HttpClient, HttpEvent, HttpParams, HttpRequest} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {UserService} from '@process-manager/pm-library';
import * as Color from 'color';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {AppConfig} from '../../../app.config';

import {isLink, Link} from '../../../shared/model/link';
import {NodeType} from '../../../shared/model/treeelement';
import {NavigationService} from '../../../shared/services/navigation.service';
import {EmbeddedDialogComponent, EmbeddedDialogData} from '../link-menu/embedded-dialog/embedded-dialog.component';
import {EmbedDetails, NoEmbedService} from './no-embed.service';
import {
  PopupDialogComponent,
  PopupDialogData
} from "../../../shared/components/dialogs/popup-dialog/popup-dialog.component";

interface ProcessDetails {
  success: boolean;
  id: string;
  nodetype: NodeType;
  label: string;
}

const NEW_URL_REGEX = /https?:\/\/.*\/domains\/.*\/trees\/([0-9]+).*/;

const colorMap: Map<string, string> = new Map<string, string>(
  [['info', '#2196F3'], ['file', '#FF9800'], ['http', '#FF9800']]);

export const getLinkColor = (link: Partial<Link>): Color => Color(link?.color || colorMap.get(link.linkType) || '#FF9800');

interface FileUploadResponse {
  token: string,
  preSignedUrl
}

@Injectable()
export class PromanLinkService {
  constructor(private http: HttpClient, private userService: UserService, private appConfig: AppConfig, private router: Router,
    private embedService: NoEmbedService, private navigationService: NavigationService, private dialog: MatDialog) {
  }

  public getProcessDetailsFromUrlOrId(originalUrlOrId: string|number): Observable<EmbedDetails> {
    const id = this.getPromanUrlId(originalUrlOrId);
    const serviceUrl = environment.api + this.userService.domain + '/processes/' + id;
    const clickUrl = this.getInternalLink(id);
    return this.http.get<ProcessDetails>(serviceUrl).pipe(map(details => {
      return {
        title: '',
        url: clickUrl,
        provider_name: 'ProcessManager internal link',
        type: 'internal link',
        html: details.label
      } as EmbedDetails
    }));
  }

  public getInternalLink(originalUrlOrId: string|number): string {
    let id: number = +originalUrlOrId;
    if(isNaN(id)) {
      id = +this.getOldPromanUrlId(originalUrlOrId as string);
    }

    return '/domains/' + this.userService.domain + '/trees/' + id;
  }

  public uploadFile(file: File): Observable<HttpEvent<FileUploadResponse>> {
    const options = {
      reportProgress: true,
      params: new HttpParams().set('name', file.name)
    };
    const serviceUrl = environment.api + this.userService.domain + '/files';
    const req = new HttpRequest('POST', serviceUrl, file, options);
    return this.http.request(req);
  }

  public showEmbedDialog(data: any) {
    this.dialog.open(EmbeddedDialogComponent, {
      data: {
        ...data,
        linkOpen: this.openLink
      } as EmbeddedDialogData,
    });
  }

  /**
   * Opens a link, either as an embedded page, externally or navigating to a tree element.
   * @param link
   * @return true if opening the link caused internal navigation
   */
  public openLink = (link: Link | string): boolean => {
    let wasNavigation = false;
    let action: string;
    let linkType: string;
    if (isLink(link)) {
      action = link.action;
      linkType = link.linkType;
    } else {
      action = link;
      linkType = 'http';
    }

    if (!!action) {
      const loc = action;
      if (linkType === 'http' && loc.startsWith('/')) {
        this.router.navigateByUrl(loc);
      } else if (this.hasNewPromanUrl(loc)) {
        this.appConfig.highlightNext = true;
        this.navigationService.navigateToNode(this.getNewPromanUrlId(loc));
        wasNavigation = true;
      } else if (this.hasOldPromanUrl(loc)) {
        this.appConfig.highlightNext = true;
        this.navigationService.navigateToNode(this.getOldPromanUrlId(loc));
        wasNavigation = true;
      } else if (linkType === 'file') {
        const newWindow = window.open(action, '_blank');
        newWindow.opener = null;
      } else if (linkType === 'http') {
        if (this.embedService.hasProvider(action)) {
          this.embedService.getEmbedDetails(action).subscribe(embedDetails => {
            if (!embedDetails.error) {
              this.showEmbedDialog(embedDetails)
            } else {
              console.log('Noembed service failure:', embedDetails.error, '  ...opening new window/tab instead');
              this.openNewWindow(action);
            }
          }, () => this.openNewWindow(action));
        } else {
          const newWindow = window.open(action, '_blank');
          newWindow.opener = null;
        }
      }
    } else {
      this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {data: {
          title: 'link-service.unsaved.link.title',
          body: 'link-service.unsaved.link'
        }})
    }
    return wasNavigation;
  }

  public hasPromanUrl(loc: string): boolean {
    return this.hasNewPromanUrl(loc) || this.hasOldPromanUrl(loc);
  }

  public getPromanUrlId(originalUrlOrId: string|number): string {
    if (isNaN(+originalUrlOrId)) {
      const originalUrl = originalUrlOrId as string;
      if (this.hasNewPromanUrl(originalUrl)) {
        return this.getNewPromanUrlId(originalUrl);
      } else if (this.hasOldPromanUrl(originalUrl)) {
        return this.getOldPromanUrlId(originalUrl);
      } else {
        return undefined;
      }
    } else {
      return originalUrlOrId + '';
    }

  }

  private openNewWindow(action: string) {
    const newWindow = window.open(action, '_blank');
    newWindow.opener = null;
  }

  private hasNewPromanUrl(loc: string) {
    return !!loc && loc.search(NEW_URL_REGEX) !== -1;
  }

  private getNewPromanUrlId(loc: string) {
    return NEW_URL_REGEX.exec(loc)[1];
  }

  private hasOldPromanUrl(loc: string) {
    return loc && (loc.search('ProcessManager') !== -1 && loc.search('flex') !== -1);
  }

  private getOldPromanUrlId(originalUrl: string): string {
    const url: URL = new URL(originalUrl);
    return url.searchParams.get('start');
  }
}
