import {Component, Input, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {NodeLabelsChanged} from '../../../../../../shared/model/history-events';

interface HistoryLabel {
  labelName: string,
  labelId: string,
  change: 'added'|'removed'
}

@Component({
  selector: 'pm-node-labels-changed-renderer',
  templateUrl: './node-labels-changed-renderer.component.html',
  styleUrls: ['./node-labels-changed-renderer.component.css']
})
export class NodeLabelsChangedRendererComponent implements OnInit {
  @Input()
  event: NodeLabelsChanged;

  constructor(private translate: TranslateService) { }

  get labels() {
    const details = (this.event as NodeLabelsChanged)?.details;
    let labelMap = (change: 'added'|'removed') => label => ({
      labelName: details.labelNames.find(item => item.labelId === label).labelName,
      labelId:label,
      change:change
    });
    const newLabels:HistoryLabel[] = details?.labels?.filter(x => !details.oldLabels?.includes(x)).map(labelMap('added')) || [];
    const oldLabels:HistoryLabel[] = details?.oldLabels?.filter(x => !details.labels?.includes(x)).map(labelMap('removed')) || [];

    return [...newLabels, ...oldLabels]?.sort((a, b) => a.labelName.localeCompare(b.labelName)) ?? [];
  }

  ngOnInit(): void {
  }

  labelTranslate(label: HistoryLabel) {
    return {
      'labelName': label.labelName,
      'labelAction': this.translate.instant('history.event.' + label.change),
    }
  }
}
