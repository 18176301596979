import {transition, trigger} from '@angular/animations';
import {Location} from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatMenu} from '@angular/material/menu';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import * as Color from 'color';
import {ColorEvent} from 'ngx-color';
import {Observable, of} from 'rxjs';
import {catchError, map, tap, withLatestFrom} from 'rxjs/operators';
import {v1} from 'uuid';
import {fadeOutAnimation, staggeredFadeInAnimation} from '../../../shared/animations';

import {Label} from '../../../shared/model/label';
import {RenderedTemplateVersion} from '../../../shared/model/rendered/rendered-bullet';
import {RenderedElement} from '../../../shared/model/rendered/rendered-element';
import {isRenderedProcess, RenderedTemplate} from '../../../shared/model/rendered/rendered-process';
import {Shape} from '../../../shared/model/shape';
import {Style} from '../../../shared/model/style';
import {isTemplateId, TemplateId} from '../../../shared/model/template-id';
import {NodeType} from '../../../shared/model/treeelement';
import {InteractService} from '../../../shared/services/interact.service';
import {NavigationService} from '../../../shared/services/navigation.service';
import {PositioningService} from '../../../shared/services/positioning.service';
import {TemplateLanguageMappingService} from '../../../shared/services/template-language-mapping.service';
import {TemplatesService} from '../../../shared/services/templates.service';

import {extractStyle} from '../../../shared/styleExtractor';
import {AddFavorite, RemoveFavorite} from '../../../state-management/actions/favorite.actions';
import {ToggleTemplatesAction} from '../../../state-management/actions/templates.actions';
import {
  ActivateElement,
  AddOrMoveRelation,
  AddTreeElement,
  DeleteTreeElement,
  LoadTree,
  UpdateTreeElementLabels,
  UpdateTreeElementSearchable,
  UpdateTreeElementStyle,
  UpdateTreeElementType
} from '../../../state-management/actions/tree.actions';
import {
  AppState,
  getAuthIsAdmin,
  getAuthIsAuthorOrAdmin,
  getAuthUserIsOnline,
  getAuthUserIsPublic,
  getTreeActiveLanguage,
  getTreeLabelArray,
  getTreeRoot,
  getTreeSource,
  selectFavorites
} from '../../../state-management/reducers';
import {ClearLogBookSaveState} from '../../../state-management/reducers/logbook-saving';
import {
  ConfirmDialogComponent,
  ConfirmDialogOptions,
  ConfirmDialogResult
} from '../../confirm-navigation/confirm-dialog.component';
import {
  TemplateDialogData,
  TemplateLanguageDialogComponent
} from '../../copy-template-dialog/template-language-dialog.component';
import {PublishTemplateDialogComponent} from '../../publish-template-dialog/publish-template-dialog.component';
import {ContactDialogComponent} from '../contact-dialog/contact-dialog.component';
import {ExtraOptionsDialogComponent} from '../extra-options-dialog/extra-options-dialog.component';
import {LinkDialogComponent} from '../link-dialog/link-dialog.component';
import {
  PopupDialogComponent,
  PopupDialogData
} from "../../../shared/components/dialogs/popup-dialog/popup-dialog.component";

// noinspection JSUnusedLocalSymbols
function assertSwitchExchausted(data: never): never {
  throw new Error('A value was missed!');
}

export function checkFadeIn(fromState: string, toState: string): boolean {
  return (fromState === 'hidden' || fromState === 'void') && toState === 'visible';
}

export function checkFadeOut(fromState: string, toState: string): boolean {
  return fromState === 'visible' && (toState === 'hidden' || toState === 'void');
}

const DEFAULT_LABEL = '<p style="text-align: left; "><span style="font-family: Verdana, sans-serif; font-size: 12px;' +
  ' color: #000000; "></span></p>';

function getNewElementType(sourceElement: RenderedElement, relation: AddOrMoveRelation,
  forceBullet: boolean): NodeType {
  if (sourceElement.nodeType === 'bullet' || forceBullet) {
    return 'bullet';
  }

  if (relation === 'under') {
    switch (sourceElement.nodeType) {
      case 'mainpage':
        return 'page';
      case 'page':
      case 'process':
        return 'process';
      default:
        assertSwitchExchausted(sourceElement.nodeType);
    }
  } else {
    return sourceElement.nodeType === 'mainpage' ? 'process' : sourceElement.nodeType;
  }
}

@Component({
  selector: 'pm-options-overlay',
  templateUrl: './options-overlay.component.html',
  styleUrls: ['./options-overlay.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [trigger('showTrigger',
    [transition(checkFadeIn, [staggeredFadeInAnimation]), transition(checkFadeOut, [fadeOutAnimation])])]
})
export class OptionsOverlayComponent implements OnInit, OnDestroy, OnChanges {
  @ViewChild(MatMenu, {static: true}) public contextMenu: MatMenu;

  lineColorSelectorOpen = false;
  bgColorSelectorOpen = false;
  @Input() context: RenderedElement | RenderedTemplate | RenderedTemplateVersion;
  @Input() contextElement: ElementRef;
  @Output() toggleEdit = new EventEmitter<boolean>();
  userIsPublic: boolean;
  userIsPublicSubscription = this.store$.select(getAuthUserIsPublic)
    .subscribe(userIsPublic => this.userIsPublic = userIsPublic);
  favorites: string[];
  favoritesSubscription = this.store$.select(selectFavorites)
    .subscribe(favorites => this.favorites = favorites && favorites.keySeq().toArray() || []);
  labels$ = this.store$.select(getTreeLabelArray);
  style: Style;
  isAuthor = false;
  isAuthorSubscription = this.store$.select(getAuthIsAuthorOrAdmin).subscribe(author => this.isAuthor = author);

  isAdmin = false;
  isAdminSubscription = this.store$.select(getAuthIsAdmin).subscribe(admin => this.isAdmin = admin);

  isDefaultLanguage = false;
  languageSubscription = this.store$.select(getTreeActiveLanguage)
    .subscribe(lang => this.isDefaultLanguage = lang && lang.isDefault || false);
  fromCustomer$ = this.store$.select(getTreeSource).pipe(map(source => source === 'customer'));

  isOnline$ = this.store$.select(getAuthUserIsOnline);

  constructor(private store$: Store<AppState>, private dialog: MatDialog,
    private navigationService: NavigationService, private translationService: TranslateService,
    private location: Location, private checkRight: PositioningService, private userService: UserService,
    private interactService: InteractService, private el: ElementRef, private templateService: TemplatesService,
    private templateMappingService: TemplateLanguageMappingService) {
  }

  get activationType() {
    return this.context.activation;
  }

  @HostBinding('class.pm-visible') get active(): boolean {
    return this.activationType !== null && this.activationType !== 'highlight';
  }

  @HostBinding('class.pm-text-edit') get textEditing(): boolean {
    return this.activationType === 'textEdit';
  }

  @HostBinding('class.pm-style-edit') get styleEditing(): boolean {
    return this.activationType === 'styleEdit';
  }

  @HostBinding('class.pm-add-edit') get addEditing(): boolean {
    return this.activationType === 'addEdit';
  }

  get bulletVisible() {
    if (this.context.style) {
      return this.context.style.shape !== Shape.NONE;
    } else {
      return true
    }
  }

  get styleArray(): Style[] {
    return [this.createStyle(Shape.RECTANGLE), this.createStyle(Shape.ELLIPSE), this.createStyle(Shape.UP_ARROW),
      this.createStyle(Shape.DOWN_ARROW), this.createStyle(Shape.LEFT_ARROW), this.createStyle(Shape.RIGHT_ARROW),
      this.createStyle(Shape.NONE)];
  }

  get hasOwner(): boolean {
    return !!this.effectiveOwner;
  }

  get canChangePageType(): boolean {
    return !this.isSubPage && isRenderedProcess(this.context);
  }

  get canChangePageTypeCurrently(): boolean {
    return isRenderedProcess(this.context) &&
      !((this.context.children && this.context.children.length > 0) || this.context.hasUnloadedChildren);
  }

  get isSubPage(): boolean {
    return this.context && this.context.nodeType === 'page' || false;
  }

  get isMainPage(): boolean {
    return this.context && this.context.nodeType === 'mainpage' || false;
  }

  get isSearchable(): boolean {
    return this.context && this.context.searchable;
  }

  get isFavorite(): boolean {
    return this.favorites && this.context && this.favorites.indexOf(this.context.id) > -1;
  }

  get isBullet() {
    return this.context && this.context.nodeType === 'bullet';
  }

  get canOverwriteSite() {
    return isTemplateId(this.context) && !!this.context.isTemplateTop && this.isAdmin;
  }

  get favoriteIcon(): string {
    return this.isFavorite ? 'star' : 'star_border';
  }

  get activeState(): string {
    return this.active ? 'visible' : 'hidden';
  }

  get styleEditState(): string {
    return this.styleEditing ? 'visible' : 'hidden';
  }

  get addEditState(): string {
    return this.addEditing ? 'visible' : 'hidden';
  }

  get effectiveOwner(): string {
    return this.context.owner;
  }

  get canEditSelf(): boolean {
    return this.canEditOrTranslate && this.isDefaultLanguage;
  }

  get canEditParent(): boolean {
    return this.canEditOrTranslate && this.context.parentWriteable && this.isDefaultLanguage;
  }

  get canEditSelfOrParent(): boolean {
    return this.canEditSelf || this.canEditParent;
  }

  get canDelete(): boolean {
    if (this.isBackup) {
      return this.isDefaultLanguage;
    }
    return this.canEditOrTranslate && this.isDefaultLanguage;
  }

  public get isBackup() {
    return this.context?.backup;
  }

  public get isTemplateId() {
    return isTemplateId(this.context);
  }

  get url(): string {
    return window.location.origin +
      this.location.prepareExternalUrl(this.navigationService.getNavigationUrl(this.context.id));
  }

  get canEditOrTranslate(): boolean {
    return this.isAuthor && this.context?.writeable && !this.isBackup;
  }

  get textEditLabel(): Observable<string> {
    if (this.isDefaultLanguage) {
      return this.translationService.get('menu.options.main.edit-text');
    } else {
      return this.translationService.get('menu.options.main.translate');
    }
  }

  get isVendor(): boolean {
    return this.userService.getSiteSettings().vendorFeatures;
  }

  onCutOrCopy(isCopy: boolean): void {
    this.interactService.setDragItem(this.contextElement, isCopy, this.context.id);
  }

  createStyle(shape: Shape): Style {
    return {
      ...this.style,
      shape: shape
    };
  }

  ngOnInit(): void {
    this.style = {...this.context.style};
  }

  ngOnChanges(changes: SimpleChanges): void {
    const contextChange = changes['context'];
    if (!!changes['activationType']) {
      this.checkRight.doCheck(this.el.nativeElement);
    }
    if (contextChange) {
      this.style = {...contextChange.currentValue.style};
    }
  }

  ngOnDestroy(): void {
    this.favoritesSubscription.unsubscribe();
    this.userIsPublicSubscription.unsubscribe();
    this.isAuthorSubscription.unsubscribe();
    this.isAdminSubscription.unsubscribe();
    this.languageSubscription.unsubscribe();
  }

  doToggleTextEdit(): void {
    this.toggleEdit.emit(!this.textEditing);
  }

  toggleStyleEdit(): void {
    this.store$.dispatch(new ActivateElement({
      id: this.context.id,
      activationType: this.activationType === 'styleEdit' ? 'active' : 'styleEdit'
    }));
  }

  toggleAddEdit(): void {
    this.store$.dispatch(new ActivateElement({
      id: this.context.id,
      activationType: this.activationType === 'addEdit' ? 'active' : 'addEdit'
    }));
  }

  toggleBullet() {
    this.store$.dispatch(new UpdateTreeElementStyle({
      updatedElementId: this.context.id,
      style: {
        ...this.context.style,
        shape: this.bulletVisible ? Shape.NONE : Shape.BULLET
      }
    }));
  }

  toggleFavorite(): void {
    if (this.isFavorite) {
      this.removeFavorite();
    } else {
      this.addFavorite();
    }
  }

  setMainPage(change: MatCheckboxChange) {
    this.store$.dispatch(new UpdateTreeElementType({
      id: this.context.id,
      type: change.checked ? 'mainpage' : 'process'
    }));
  }

  setSearchable(change: MatCheckboxChange) {
    this.store$.dispatch(new UpdateTreeElementSearchable({
      id: this.context.id,
      searchable: change.checked
    }));
  }

  editOwnerAndPermissions(): void {
    this.dialog.open(ExtraOptionsDialogComponent, {
      data: this.context
    });
  }

  addFavorite(): void {
    if(/^\d+$/.test(this.context.id)) {
      this.store$.dispatch(new AddFavorite({
        favorite: {
          id: this.context.id, label: this.context.label.replace(/<[^>]+>/ig, '')
        }
      }));
    } else {
      this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
        data: {
          body: 'menu.options.main.favorite.error.new'
        }
      })
    }
  }

  lineColorChange(newColor: ColorEvent) {
    this.style = {
      ...this.style,
      lineColor: newColor.color.hex
    };
  }

  bgColorChange(newColor: ColorEvent) {
    this.style = {
      ...this.style,
      bgColor: newColor.color.hex
    };
  }

  updateElementShape(shape: Shape): void {
    if (shape !== this.style.shape) {
      this.style = {
        ...this.style,
        shape: shape
      };
      this.updateElementStyle(true);
    }
  }

  updateElementStyle(force?: boolean): void {
    if (force || this.style.bgColor !== this.context.style.bgColor || this.style.lineColor !==
      this.context.style.lineColor) {
      this.store$.dispatch(new UpdateTreeElementStyle({
        updatedElementId: this.context.id,
        style: {
          ...this.style,
          id: v1(),
          serverId: undefined
        }
      }));
    }
  }

  removeFavorite(): void {
    this.store$.dispatch(new RemoveFavorite(this.context.id));
  }

  popupContactDialog() {
    this.dialog.open(ContactDialogComponent, {
      data: {
        ...this.context,
        owner: this.effectiveOwner
      },
      disableClose: true
    });
  }

  createProcessOrBullet(relation: AddOrMoveRelation, bullet: boolean = false) {
    this.translationService.get('element.new.text').subscribe(text => {
      const id = v1();
      const url = this.navigationService.getNavigationUrl(id, this.userIsPublic);

      const newElementType = getNewElementType(this.context, relation, bullet);
      const isBullet = newElementType === 'bullet';

      const newElement = {
        id: id,
        label: this.extractStyleIfCorrectTypes(text, bullet),
        url: url,
        links: [],
        type: newElementType,
        style: isBullet ? undefined : this.context.style
      };

      this.store$.dispatch(new AddTreeElement({
        sourceElementId: id,
        targetElementId: this.context.id,
        relation: relation,
        forceBullet: bullet,
        newElement: newElement
      }));
    });
  }

  deleteItem() {
    this.store$.dispatch(new DeleteTreeElement({id: this.context.id}));
  }

  addResource() {
    this.store$.dispatch(new ActivateElement({
      id: this.context.id,
      activationType: 'active'
    }));

    this.dialog.open(LinkDialogComponent, {
      data: this.context
    });
  }

  // TODO: A bit nasty to couple functionality to animation.
  captureAnimationStart() {
    this.checkRight.doCheck(this.el.nativeElement);
  }

  isDark(label: Label) {
    return Color(label.color).isDark();
  }

  setLabel($event: MatCheckboxChange, label: Label) {
    const labels = this.context.labels.map(contextLabel => contextLabel.id);
    if ($event.checked && !labels.includes(label.id)) {
      this.store$.dispatch(new UpdateTreeElementLabels({
        updatedElementId: this.context.id,
        labelIds: [...labels, label.id]
      }));
    } else if (!$event.checked && labels.includes(label.id)) {
      this.store$.dispatch(new UpdateTreeElementLabels({
        updatedElementId: this.context.id,
        labelIds: labels.filter(cand => cand !== label.id)
      }));
    }
  }

  hasLabel(label: Label) {
    return this.context.labels?.some(contextLabel => contextLabel?.id === label?.id) || false;
  }

  publishTemplateVersion() {
    this.dialog.open(PublishTemplateDialogComponent, {
      data: this.context
    });
  }

  replaceSite() {
    if (!isTemplateId(this.context)) {
      throw new Error('Context is not template id, cannot use it for replacing site');
    }

    if (!this.templateMappingService.hasMapping()) {
      const config: MatDialogConfig<TemplateDialogData> = {
        data: {
          node: this.context,
          type: 'missing'
        }
      };
      this.dialog.open(TemplateLanguageDialogComponent, config).afterClosed().subscribe(result => {
        if (!!result) {
          this.doOverwriteSiteWithTemplate();
        }
      });
    } else {
      this.doOverwriteSiteWithTemplate();
    }
  }

  private doOverwriteSiteWithTemplate() {
    this.dialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(ConfirmDialogComponent,
      {
        data: {
          title: 'dialog.confirm.overwrite.title',
          body: 'dialog.confirm.overwrite.description',
          hideSaveButton: true,
          continueButton: 'button.overwrite',
          onContinue: (): Observable<string | null> => {
            return this.templateService.overwriteSite(this.context as TemplateId)
              .pipe(withLatestFrom(this.store$.select(getTreeRoot)), tap(() => {
                  this.store$.dispatch(new ClearLogBookSaveState());
                  this.store$.dispatch(new LoadTree());
                  this.store$.dispatch(new ToggleTemplatesAction({forcedState: 'closed'}));
                }), map(() => null),
                catchError(() => of(this.translationService.instant('menu.options.overwrite.error'))));
          }
        }
      });
  }

  private extractStyleIfCorrectTypes(text, isBullet) {
    if (isBullet && this.context.nodeType !== 'bullet') {
      return extractStyle(text, DEFAULT_LABEL);
    } else {
      return extractStyle(text, this.context.label || DEFAULT_LABEL);
    }
  }
}
