import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output
} from '@angular/core';
import {Store} from '@ngrx/store';

import {Observable, of, Subject, Subscription} from 'rxjs';
import {v1} from 'uuid';
import {Link} from '../../../../shared/model/link';
import {RenderedElement} from '../../../../shared/model/rendered/rendered-element';
import {ChangeResourceColor} from '../../../../state-management/actions/tree.actions';
import {AppState, getAuthIsAuthorOrAdmin, getTreeActiveLanguage} from '../../../../state-management/reducers';
import {EmbedDetails, NoEmbedService} from '../../shared/no-embed.service';
import {getLinkColor, PromanLinkService} from '../../shared/proman-link.service';

@Component({
  selector: 'pm-embedcard',
  templateUrl: './embedcard.component.html',
  styleUrls: ['./embedcard.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmbedCardComponent implements OnInit, OnDestroy {
  @Input() link: Link;
  @Input() node: RenderedElement;
  @Output() remove = new EventEmitter<void>();
  @Output() linkClicked = new EventEmitter<void>();

  cardClick$ = new Subject<void>();
  embedDetails$: Observable<EmbedDetails>;
  embedDetails: EmbedDetails;
  isDefaultLanguage = false;
  languageSubscription = this.store$.select(getTreeActiveLanguage)
    .subscribe(lang => this.isDefaultLanguage = lang && lang.isDefault || false);
  private cardClickSubscription: Subscription = this.cardClick$.subscribe(() => this.cardClicked());
  private _subscription: Subscription;
  isAuthorOrAdmin$ = this.store$.select(getAuthIsAuthorOrAdmin);

  constructor(private embedService: NoEmbedService, private promanLinkService: PromanLinkService,
    private changeDetector: ChangeDetectorRef, private store$: Store<AppState>) {
  }

  get hasContent(): boolean {
    return !!this.embedDetails &&
      (this.link.linkType === 'file' || this.embedDetails.type === 'internal link' || this.embedDetails.type ===
        'external link');
  }

  get hasImageSrc(): boolean {
    return !!this.embedDetails && !!this.embedDetails.thumbnail_url;
  }

  get isInheritedLink(): boolean {
    return this.link.defLink && !this.isDefaultLanguage;
  }

  get hasTitle(): boolean {
    return !!this.embedDetails && !!this.embedDetails.title;
  }

  get title(): string {
    return this.embedDetails && this.embedDetails.title || '';
  }

  get imageSrc(): any {
    return this.embedDetails && this.embedDetails.thumbnail_url || '';
  }

  get htmlContent(): string {
    return this.embedDetails && this.embedDetails.html || '';
  }

  get embedType(): string {
    return this.embedDetails && this.embedDetails.type || '';
  }

  get linkColor() {
    return getLinkColor(this.link).hex();
  }

  ngOnInit() {
    if (this.link.linkType === 'http') {
      const loc = this.link.action;
      if (!!this.promanLinkService.hasPromanUrl(loc)) {
        this.embedDetails$ = this.promanLinkService.getProcessDetailsFromUrlOrId(loc);
      } else {
        const hasProvider = this.embedService.hasProvider(this.link.action);
        if (hasProvider) {
          this.embedDetails$ = this.embedService.getEmbedDetails(this.link.action);
        } else {
          try {
            this.embedDetails$ = of(this.getBasicLinkDetails());
          } catch (err) {
            console.log(err.message);
          }
        }

      }
    } else if (this.link.linkType === 'file') {
      this.embedDetails$ = of({
        title: '',
        provider_name: 'file',
        url: this.link.action,
        type: this.link.linkType,
        html: this.link.label
      } as EmbedDetails);

    }

    if (this.embedDetails$) {
      this._subscription = this.embedDetails$.subscribe(embedDetails => {
        if (embedDetails.error) {
          console.log('Noembed service failure:', embedDetails.error, '  ...opening new window/tab instead');
          this.embedDetails = this.getBasicLinkDetails();
        } else {
          this.embedDetails = embedDetails;
        }
        this.changeDetector.markForCheck();
      }, error => {
        console.log('Noembed service failure:', error, '  ...opening new window/tab instead');
        this.embedDetails = this.getBasicLinkDetails();
        this.changeDetector.markForCheck();
      });
    }
  }

  ngOnDestroy(): void {
    this._subscription?.unsubscribe();
    this.languageSubscription?.unsubscribe();
    this.cardClickSubscription.unsubscribe();
  }

  onRemove($event: Event) {
    this.stopPropagation($event);
    this.remove.emit();
  }

  cardClicked(): void {
    this.linkClicked.emit();
  }

  imageLoaded(event: any) {
    event.target.classList.add('loaded');
  }

  colorChanged(newColor: string) {
    this.store$.dispatch(new ChangeResourceColor({
      elementId: this.node.id,
      oldResourceId: this.link.id,
      newResourceId: v1(),
      color: newColor
    }));
  }

  stopPropagation($event: Event) {
    if ($event.stopImmediatePropagation) {
      $event.stopImmediatePropagation();
    }
    if ($event.stopPropagation) {
      $event.stopPropagation();
    }
  }

  private getBasicLinkDetails() {
    return {
      title: this.link.label,
      url: this.link.action,
      provider_name: new URL(this.link.action).host,
      type: 'external link',
      html: this.link.action
    } as EmbedDetails;
  }
}
