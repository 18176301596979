// noinspection JSUnusedGlobalSymbols

import {HttpErrorResponse, HttpStatusCode} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {MatSnackBar} from '@angular/material/snack-bar';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {Action, Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';
import {from, fromEvent, Observable, of} from 'rxjs';
import {catchError, concatMap, map, mergeMap, pairwise, switchMap, tap, withLatestFrom} from 'rxjs/operators';
import {PasswordChangeDialogComponent} from '../../main/password-change-dialog/password-change-dialog.component';
import {
  CantOpenDialogComponent, CantOpenDialogData
} from '../../shared/components/dialogs/cant-open-dialog/cant-open-dialog.component';
import {
  GenericErrorDialogComponent
} from '../../shared/components/dialogs/generic-error-dialog/generic-error-dialog.component';
import {SaveErrorDialogComponent} from '../../shared/components/dialogs/save-error-dialog/save-error-dialog.component';
import {
  SaveWarningsDialogComponent
} from '../../shared/components/dialogs/save-warnings-dialog/save-warnings-dialog.component';
import {Process} from '../../shared/model/process';
import {isTemplateId} from '../../shared/model/template-id';
import {Tree} from '../../shared/model/tree';
import {TreeElement} from '../../shared/model/treeelement';
import {HistoryLoaderService} from '../../shared/services/history-loader.service';
import {DATA_NODE_ID, InteractService} from '../../shared/services/interact.service';
import {LanguageService} from '../../shared/services/language.service';
import {NavigationService} from '../../shared/services/navigation.service';
import {OfflineTreeService} from '../../shared/services/offline-tree.service';
import {ActionWarning, resultIsSaveTreeResult, ServerIds, TreeService} from '../../shared/services/tree.service';
import {VisitService} from '../../shared/services/visit.service';
import {TreeMerger} from '../../shared/tree-building/tree.merger';
import {LOGIN_SUCCESS, LoginSuccess, Offline} from '../actions/auth.actions';
import {
  AddTreeElement, ChangeLanguage, ClearHistory, DelayedNavigation, ErrorAction, LoadTree, LoadTreeError,
  LoadTreeSuccess, MoveTreeElement, RemoveResource, SaveSteps, SaveStepsComplete, SaveStepsFailed, TreeActionTypes,
  UpdateServerIds, UpdateTreeElementText
} from '../actions/tree.actions';
import {MarkTranslationChange, ViewOptionActionTypes} from '../actions/view-options.actions';
import {getPreferredLanguage} from '../helpers/getPreferredLanguage';
import {
  AppState, getAuthUser, getAuthUserIsPublic, getAuthUserStartNode, getLoginPageTemporaryPassword,
  getTranslationHighlight, getTreeActiveLanguage, getTreeDefaultLanguage, getTreeHistoryActions, getTreeNodes,
  getTreeOpenId, getTreeRoot, getTreeSource, HighlightSelectionType, selectTreeState
} from '../reducers/';
import {getLoadEffect, getTargetParent} from '../reducers/tree.reducer';


@Injectable()
export class TreeEffects {
  languageChange$ = createEffect(() => this.actions$.pipe(ofType<ChangeLanguage>(TreeActionTypes.CHANGE_LANGUAGE),
    withLatestFrom(this.store$.select(getTranslationHighlight)), tap(([action, highlight]) => {
      if (!!action.payload && action.payload.source === 'customer' &&
        (!action.payload.oldLanguage || action.payload.newLanguage !== action.payload.oldLanguage)) {
        if (!!action.payload.newLanguage) {
          localStorage.setItem(this.userService.domain + UserService.LANGUAGE_SELECTED_POSTFIX,
            action.payload.newLanguage.id + '');
          this.languageService.changeUiLanguage(action.payload.newLanguage.interfaceLanguage);
          this.historyLoader.forceReload();
        }
        if (action.payload.doReload) {
          this.store$.dispatch(new LoadTree());
        } else if (highlight !== 'off') {
          this.synchronizeHighlights(highlight);
        }
      }
    })), {dispatch: false});
  load$: Observable<Action> = createEffect(() => this.actions$.pipe(ofType<LoadTree>(TreeActionTypes.LOAD_TREE),
    withLatestFrom(this.store$.select(getAuthUser), this.store$.select(selectTreeState),
      this.store$.select(getAuthUserIsPublic), this.store$.select(getTreeActiveLanguage)),
    switchMap(([action, user, treeState, userIsPublic, activeLanguage]) => {
      try {
        const oldTree = treeState.tree;
        const nodeId = action.payload && action.payload.id || null;
        const loadEffect = getLoadEffect(nodeId, treeState, user);

        if (loadEffect === null) {
          return of(new LoadTreeError(null));
        }

        const numericNodeId = loadEffect.treeElement && loadEffect.treeElement.serverId ||
          parseInt(loadEffect.nodeId, 10);

        if(isNaN(numericNodeId)) {
          return of(new LoadTreeError({
            error: new Error('Node ID must be numeric or exist locally'),
            action: action,
            errorCode: HttpStatusCode.NotFound,
            id: loadEffect.nodeId}
          ));
        }

        if (!!action.payload?.template?.templateId) {
          return of(new LoadTreeSuccess({
            tree: oldTree,
            wipeHistory: false,
            openNode: action.payload.id || this.findRealRoot(oldTree).id,
            language: activeLanguage
          }));
        }

        this.visitService.registerVisit(numericNodeId, false);
        if (loadEffect.action === 'nothing') {
          return of(new LoadTreeSuccess({
            tree: oldTree,
            wipeHistory: false,
            language: activeLanguage
          }));
        } else {
          const onlyLoadChildren = loadEffect.action === 'loadChildren';

          this.navigationService.replaceNode(numericNodeId);


          if (treeState.tree.source === 'offline' || (!treeState.tree.source && !window.navigator.onLine)) {
            return from(this.offlineService.getTree()).pipe(map(loadedTree => new LoadTreeSuccess({
              tree: loadedTree,
              wipeHistory: true,
              openNode: numericNodeId + '',
              language: activeLanguage
            })))
          } else {
            return this.treeService.getTree(numericNodeId, onlyLoadChildren, userIsPublic).pipe(
              map(newTree => onlyLoadChildren ? new TreeMerger(oldTree, newTree).merge(loadEffect.nodeId) : newTree),
              map(loadedTree => new LoadTreeSuccess({
                tree: loadedTree,
                wipeHistory: !onlyLoadChildren,
                openNode: numericNodeId + '',
                language: isTemplateId(oldTree.source) ? getPreferredLanguage(activeLanguage,
                  this.userService.getSiteSettings().languages) : activeLanguage
              })), catchError((err: HttpErrorResponse) => of(new LoadTreeError({
                error: err,
                action: action,
                errorCode: err.status,
                id: numericNodeId + ''
              }))));
          }
        }
      } catch (e) {
        return of(new LoadTreeError({
          error: e,
          action: action,
          id: '',
          errorCode: -1
        }));
      }
    })));
  newElementAdded = createEffect(
    () => this.actions$.pipe(ofType<AddTreeElement>(TreeActionTypes.ADD_TREE_ELEMENT), tap(action => {
      window.setTimeout(() => {
        const target = document.querySelector(`[${DATA_NODE_ID}='${action.payload.newElement.id}'`);
        if (!!target?.scrollIntoView) {
          target.scrollIntoView({
            inline: 'center',
            block: 'center',
            behavior: 'smooth'
          });
        }
      }, 0);
    })), {dispatch: false});
  errorAction = createEffect(
    () => this.actions$.pipe(ofType<ErrorAction>(TreeActionTypes.SAVE_STEPS_FAILED), tap(action => {
      const error = action.payload.error;
      console.error(action.type, error, '\n... While doing action:', action.payload.action);

      if (error instanceof HttpErrorResponse && error.status === HttpStatusCode.Conflict) {
        this.dialog.open<SaveErrorDialogComponent, HttpErrorResponse>(SaveErrorDialogComponent, {
          data: error
        }).afterClosed().subscribe(result => {
          if(result === 'force') {
            this.store$.dispatch(new SaveSteps({
              forceId: error.error.details.target
            }))
          }
        });
      } else {
        this.dialog.open(GenericErrorDialogComponent, {
          data: action
        });
      }
    })), {dispatch: false});
  translationHighlightAction = createEffect(
    () => this.actions$.pipe(ofType<MarkTranslationChange>(ViewOptionActionTypes.MARK_TRANSLATION_CHANGE), pairwise(),
      tap(([oldAction, newAction]) => {
        if (oldAction.payload.type !== 'off' && newAction.payload.type !== 'off') {
          this.synchronizeHighlights(newAction.payload.type);
        }
      })), {dispatch: false});
  loadTreeSuccess = createEffect(() => this.actions$.pipe(ofType<LoadTreeSuccess>(TreeActionTypes.LOAD_TREE_SUCCESS),
    withLatestFrom(this.store$.select(getTranslationHighlight), this.store$.select(getTreeActiveLanguage)),
    tap(([action, highlight, activeLanguage]) => {
      if (highlight !== 'off') {
        this.synchronizeHighlights(highlight);
      }
      this.store$.dispatch(new ChangeLanguage({
        source: 'customer',
        newLanguage: action.payload.language,
        oldLanguage: activeLanguage
      }))
    })), {dispatch: false});
  notFoundAction = createEffect(() => this.actions$.pipe(ofType<LoadTreeError>(TreeActionTypes.LOAD_TREE_ERROR),
    withLatestFrom(this.store$.select(getAuthUserStartNode), this.store$.select(getTreeRoot)),
    tap(([action, startNode, root]) => {
      if (action.payload === null) {
        return;
      }

      console.error(action.type, action.payload.error, '\n... While doing action:', action.payload.action);

      if ([403, 404].includes(action.payload.errorCode)) {
        const isStartNode = !root && action.payload.id === startNode + '';
        this.dialog.open<CantOpenDialogComponent, CantOpenDialogData>(CantOpenDialogComponent, {
          data: {
            userStartNode: startNode + '',
            noLoadedNodes: !isStartNode && !root,
            isStartNode: isStartNode,
            nodeId: action.payload.id,
            reason: action.payload.errorCode
          },
          disableClose: isStartNode
        });
      } else {
        this.dialog.open(GenericErrorDialogComponent, {
          data: action
        })
      }
    })), {dispatch: false});
  saveTree = createEffect(() => this.actions$.pipe(ofType<SaveSteps>(TreeActionTypes.SAVE_STEPS),
    withLatestFrom(this.store$.select(getTreeHistoryActions)),
    map(([action, actionHistory]): [SaveSteps, Action[]] => {
      if(!!action.payload?.forceId) {
        actionHistory = actionHistory.map(historyAction => {
          if(historyAction.type === TreeActionTypes.MOVE_TREE_ELEMENT) {
            const moveAction = historyAction as MoveTreeElement;

            if (moveAction.payload.sourceElementId === action.payload.forceId + '') {
              return {
                ...moveAction,
                payload: {...moveAction.payload, force: true}
              }
            }
            return historyAction;
          }
        });
      }
      return [action, actionHistory];
    }),
    mergeMap(([action, actionHistory]) => this.treeService.sendActions(actionHistory).pipe(concatMap(result => {
      let updatedIds: ServerIds = null;
      let warnings: ActionWarning[] = null;
      if(resultIsSaveTreeResult(result)) {
        updatedIds = result.idChanges;
        warnings = result.warnings;
      } else {
        updatedIds = result;
      }

      if(warnings?.length > 0) {
        this.dialog.open<SaveWarningsDialogComponent, ActionWarning[]>(SaveWarningsDialogComponent, {
          data: warnings
        })
      }
      const steps = [new SaveStepsComplete(), new UpdateServerIds({updatedIds}), new ClearHistory()];
      return !!action.payload && [...steps, action.payload.nextAction] || [...steps, new LoadTree()];
    }), catchError(error => {
      return of(new SaveStepsFailed({
        error,
        action
      }));
    })))));
  saveStepsComplete = createEffect(
    () => this.actions$.pipe(ofType<SaveStepsComplete>(TreeActionTypes.SAVE_STEPS_COMPLETE),
      tap(() => {
        this.historyLoader.forceReload();
        this.offlineService.storeTree();
      })), {dispatch: false});
  updateTreeElementText = createEffect(
    () => this.actions$.pipe(ofType<UpdateTreeElementText>(TreeActionTypes.UPDATE_TREE_ELEMENT_TEXT),
      withLatestFrom(this.store$.select(getTreeActiveLanguage)), tap(([action, language]) => {
        if (!language.isDefault && action.payload.label.replace(/(<[^>]*>)+/, '').trim() === '') {
          this.snackBar.open(this.translationService.instant('warning.translation-removed'), undefined, {
            politeness: 'polite'
          })
        }
      })), {dispatch: false});
  updateServerIds = createEffect(
    () => this.actions$.pipe(ofType<UpdateServerIds>(TreeActionTypes.UPDATE_SERVER_IDS), tap(action => {
      this.interact.updateCurrentDragItem(action.payload.updatedIds);
    })), {dispatch: false});
  // noinspection JSUnusedLocalSymbols
  userLoggedIn = createEffect(() => this.actions$.pipe(ofType<LoginSuccess>(LOGIN_SUCCESS),
    withLatestFrom(this.store$.select(getLoginPageTemporaryPassword)), tap(([action, temporaryPassword]) => {
      const changePassword = localStorage.getItem(UserService.CHANGE_PASSWORD_NAME);
      if (!!temporaryPassword || changePassword === 'true' || changePassword === '1') {
        this.dialog.open(PasswordChangeDialogComponent, {
          data: {
            currentPassword: temporaryPassword,
            passwordChangeRequired: true,
            username: this.userService.user.username
          },
          disableClose: true
        })
      }
    })), {dispatch: false});
  added = createEffect(() => this.actions$.pipe(
    ofType<AddTreeElement | MoveTreeElement>(TreeActionTypes.ADD_TREE_ELEMENT, TreeActionTypes.MOVE_TREE_ELEMENT),
    withLatestFrom(this.store$.select(selectTreeState)), tap(([action, tree]) => {
      const nodes = tree.tree.nodes;
      const newNode = nodes.get(action.payload.sourceElementId) || action.payload.backupElementData?.treeElement;
      if (!!newNode) { // Might not exist if copied from template.
        const parent = getTargetParent(tree, action.payload);

        if (parent.type !== 'page') {
          if (newNode.type === 'bullet') {
            if (nodes.get(tree.tree.root).type !== 'mainpage') {
              this.navigationService.navigateToNode(parent.parentId);
            }
          } else if (this.shouldOpen(parent)) {
            this.navigationService.navigateToNode(parent.id);
          }
        }
      }
    })), {dispatch: false});
  removedResource = createEffect(() => this.actions$.pipe(ofType<RemoveResource>(TreeActionTypes.REMOVE_RESOURCE),
    withLatestFrom(this.store$.select(getTreeDefaultLanguage), this.store$.select(getTreeNodes)),
    tap(([action, defaultLanguage, nodes]) => {
      if (!defaultLanguage && ['upload', 'link'].includes(action.payload.resourceType) &&
        !nodes.get(action.payload.elementId)?.links?.length) {
        this.snackBar.open(this.translationService.instant('warning.translation-removed.links'), undefined, {
          politeness: 'polite'
        })
      }
    })), {dispatch: false});

  delayedLoad = createEffect(
    () => this.actions$.pipe(ofType<DelayedNavigation>(TreeActionTypes.DELAYED_NAVIGATION), tap(action => {
      this.navigationService.navigateToNode(action.payload.id);
    })), {dispatch: false});

  offline = fromEvent(window, 'offline')
    .pipe(withLatestFrom(this.store$.select(getTreeSource), this.store$.select(getTreeActiveLanguage), this.store$.select(getTreeOpenId)))
    .subscribe(([event, treeSource, currentLanguage, currentId]) => {
      if(localStorage.getItem('detectOffline') === 'false') {
        return;
      }
      if (!!treeSource && treeSource !== 'offline') {
        this.offlineService.offlineDialog(this.userService.domain, true).subscribe((async result => {
          if (result === 'offline') {
            this.store$.dispatch(new Offline());
          }
        }));
      }
    });

  online = fromEvent(window, 'online')
    .pipe(withLatestFrom(this.store$.select(getTreeSource), this.store$.select(getTreeActiveLanguage), this.store$.select(getTreeOpenId)))
    .subscribe(([event, treeSource, currentLanguage, currentId]) => {
      if(localStorage.getItem('detectOffline') === 'false') {
        return;
      }

      if (!!treeSource && treeSource === 'offline') {
        this.offlineService.onlineDialog(this.userService.domain, true).subscribe((async result => {
          if (result === 'online') {
            document.location.reload();
          }
        }));
      }
    });

  constructor(private actions$: Actions, private treeService: TreeService, private store$: Store<AppState>,
    private snackBar: MatSnackBar, private dialog: MatDialog, private languageService: LanguageService,
    private navigationService: NavigationService, private userService: UserService, private visitService: VisitService,
    private interact: InteractService, private translationService: TranslateService,
    private historyLoader: HistoryLoaderService, private offlineService: OfflineTreeService) {
  }
  private findRealRoot(tree: Tree): TreeElement {
    let candidate = tree.nodes.get(tree.root);
    if (!candidate) {
      return null;
    }
    while (!!candidate.parentId && candidate.parentId !== candidate.id) {
      candidate = tree.nodes.get(candidate.parentId);
    }
    return candidate;
  }
  private synchronizeHighlights(realType: HighlightSelectionType) {
    this.store$.dispatch(new MarkTranslationChange({type: 'off'}))
    setTimeout(() => this.store$.dispatch(new MarkTranslationChange({type: realType})), 100);
  }

  private shouldOpen(targetParent: Process) {
    const needsSave = (!targetParent.serverId && (targetParent as Process).hasUnloadedChildren);
    return (!needsSave);
  }
}
