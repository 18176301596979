import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {take} from 'rxjs/operators';
import {Logout} from '../../../../state-management/actions/auth.actions';
import {LoadTree} from '../../../../state-management/actions/tree.actions';
import {AppState, getAuthDomain} from '../../../../state-management/reducers';

export interface CantOpenDialogData {
  nodeId: string;
  userStartNode: string;
  noLoadedNodes: boolean;
  isStartNode: boolean;
  reason: number
}

@Component({
  selector: 'pm-not-found-dialog',
  templateUrl: './cant-open-dialog.component.html',
  styleUrls: ['./cant-open-dialog.component.css']
})
export class CantOpenDialogComponent implements OnInit {
  constructor(private dialogRef: MatDialogRef<CantOpenDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: CantOpenDialogData, private store$: Store<AppState>) {
  }

  ngOnInit() {
    this.dialogRef.beforeClosed().pipe(take(1)).subscribe(() => {
      if (this.data.noLoadedNodes) {
        this.store$.dispatch(new LoadTree({id: this.data.userStartNode}));
      }
    })
  }

  logout() {
    this.store$.select(getAuthDomain).pipe(take(1)).subscribe(domain => this.store$.dispatch(new Logout({domain})));
  }
}
