import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from '../../../../app.config';
import {
  EventTypes, HistoryEventBlock, LabelEvent, NodeEvent, NodeLabelsChanged, NodeLinksChanged, ResourceColorChanged
} from '../../../../shared/model/history-events';
import {NavigationService} from '../../../../shared/services/navigation.service';
import {ChangeResourceColor} from '../../../../state-management/actions/tree.actions';
import {AppState, getAuthUserIsPublic} from '../../../../state-management/reducers';

export function isNodeOverview(cand: any): cand is NodeOverView {
  return !!cand && cand.hasOwnProperty('nodeId');
}

interface NodeOverView {
  nodeId: number,
  nodeName: string,
  nodeType: string,
  events: NodeEvent[],
  stillExists: boolean
}

interface LabelOverView {
  labelId: number,
  labelName: string,
  events: LabelEvent[]
}


interface ResourceOverview {
  resourceId: number,
  resourceName: string,
  resourceType: string,
  events: ResourceColorChanged[]
}

@Component({
  selector: 'pm-event-block-view',
  templateUrl: './event-block-view.component.html',
  styleUrls: ['./event-block-view.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EventBlockViewComponent implements OnInit, OnDestroy {
  readonly NodeLabelsChanged: NodeLabelsChanged;
  readonly NodeLinksChanged: NodeLinksChanged;
  readonly NodeEvent: NodeEvent;
  readonly ResourceColorChanged: ResourceColorChanged;

  readonly EventTypes = EventTypes;
  @Input() eventBlock: HistoryEventBlock;

  isPublic: boolean;
  isPublicSubscription = this.store$.select(getAuthUserIsPublic).subscribe(isPublic => this.isPublic = isPublic);

  constructor(private store$: Store<AppState>, private appConfig: AppConfig, private translate: TranslateService,
    private navigationService: NavigationService) {
  }

  get changeEvents(): (NodeOverView|LabelOverView|ResourceOverview)[] {
    return [...this.nodeChangeEvents, ...this.labelChangeEvents, ...this.resourceColorChangeEvents];
  }

  get resourceColorChangeEvents(): ResourceOverview[] {
    return Object.values(this.eventBlock?.events?.filter(event => event.eventType === EventTypes.RESOURCE_COLOR_CHANGED).reduce((acc, event: ResourceColorChanged) => {
      const key = 'id' + event.details.resourceId;
      if (!acc[key]) {
        acc[key] = {
          resourceId: event.details.resourceId,
          resourceType: event.details.resourceType,
          resourceName: event.details.resourceName,
          events: [event]
        } as ResourceOverview
      } else {
        acc[key].events.push(event);
      }
      return acc;
    }, {} as { [key: string]: ResourceOverview }) ?? []);
  }

  get nodeChangeEvents(): NodeOverView[] {
    return Object.values(this.eventBlock?.events?.filter(event => {
      return !!event['details']['nodeId'] && (event.eventType !== 'nodeTextChanged' || !!(event as NodeEvent)?.details?.nodeName);
    }).reduce((acc, event: NodeEvent) => {
      const key = 'id' + event.details.nodeId;
      if (!acc[key]) {
        acc[key] = {
          nodeId: event.details.nodeId,
          nodeName: event.details.nodeName || '',
          nodeType: event.details.nodeType,
          stillExists: event.details.stillExists,
          searchable: event.details.searchable,
          events: [event]
        } as NodeOverView
      } else {
        acc[key].events.push(event);
      }

      return acc;
    }, {} as { [key: string]: NodeOverView }) ?? [])
      .sort((a: NodeOverView, b: NodeOverView) => ('' + a.nodeName).localeCompare(b.nodeName));
  }

  get labelChangeEvents(): LabelOverView[] {
    return Object.values(this.eventBlock?.events?.filter(event => !!event.details['labelId']).reduce((acc, event: LabelEvent) => {
      const key = 'id' + event.details.labelId;
      if (!acc[key]) {
        acc[key] = {
          labelId: event.details.labelId,
          labelName: event.details.labelName,
          events: [event]
        } as LabelOverView
      } else {
        acc[key].events.push(event);
      }
      return acc;
    }, {} as { [key: string]: LabelOverView }) ?? [])
      .sort((a: LabelOverView, b: LabelOverView) => ('' + a.labelName).localeCompare(b.labelName));
  }

  ngOnInit(): void {
  }

  ngOnDestroy() {
    this.isPublicSubscription.unsubscribe();
  }

  nodeChangeBlockTranslation(nodeChangeBlock: NodeOverView) {
    return {
      'nodeName': nodeChangeBlock.nodeName || '(untitled)',
      'nodeType': this.translate.instant('history.event-block.node-type.' + nodeChangeBlock.nodeType),
      'nodeId': nodeChangeBlock.nodeId
    }
  }

  resourceChangeBlockTranslation(resourceChangeBlock: ResourceOverview) {
    return {
      'resourceName': resourceChangeBlock.resourceName || '(untitled)',
      'resourceType': this.translate.instant('history.event-block.resource-type.' + resourceChangeBlock.resourceType),
      'resourceId': resourceChangeBlock.resourceId
    }
  }
  isActive(changeBlock: NodeOverView | LabelOverView) {
    return isNodeOverview(changeBlock) && !!changeBlock.stillExists;
  }

  browseToNode(changeBlock: NodeOverView | LabelOverView) {
    if (this.isActive(changeBlock)) {
      this.appConfig.highlightNext = true;
      this.navigationService.navigateToNode(changeBlock['nodeId'], this.isPublic);
    }
  }

  protected readonly ChangeResourceColor = ChangeResourceColor;
}
