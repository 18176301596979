import {LOGIN_SUCCESS, LoginSuccess} from '../actions/auth.actions';
import {LoadTree, TreeActionTypes} from '../actions/tree.actions';
import {ViewOptionActions, ViewOptionActionTypes} from '../actions/view-options.actions';

export type HighlightSelectionType = | 'off' | 'untranslated' | 'translated';

export interface LabelSettings {
  inherit: boolean;
  show: boolean;
  descendents: boolean;
  enableFilter: boolean;
  labels?: number[];
}

export interface ViewOptionsState {
  labelSettings: LabelSettings;
  smallLabels: boolean;
  translationHighlight: HighlightSelectionType;
  showUnsearchable: boolean;
}

const initialState: ViewOptionsState = {
  labelSettings: {
    show: true,
    inherit: false,
    descendents: false,
    enableFilter: false
  },
  smallLabels: false,
  translationHighlight: 'off',
  showUnsearchable: false
}

export function viewOptionsReducer(state = initialState, action: ViewOptionActions | LoginSuccess | LoadTree): ViewOptionsState {
  switch (action.type) {
    case ViewOptionActionTypes.MARK_TRANSLATION_CHANGE:
      return {
        ...state,
        translationHighlight: action.payload.type
      }
    case ViewOptionActionTypes.LABEL_SETTINGS_CHANGE:
      return {
        ...state,
        labelSettings:  action.payload.settings
      }
    case ViewOptionActionTypes.LABEL_SIZE_CHANGE: {
      let smallLabels = !state.smallLabels;
      if (action.payload?.force !== undefined) {
        smallLabels = action.payload.force;
      }

      return {
        ...state,
        smallLabels: smallLabels
      }
    }
    case ViewOptionActionTypes.NOT_SEARCHABLE_CHANGE: {
      return {
        ...state,
        showUnsearchable: action.payload.show
      }
    }
    case LOGIN_SUCCESS:
      return {
        ...state,
        translationHighlight: 'off'
      }
    case TreeActionTypes.LOAD_TREE:
      return {
        ...state,
        translationHighlight: !!action.payload?.highlight ? 'off' : state.translationHighlight
      }
    default: return state;
  }
}

export const getTranslationHighlight = (viewOptionsState: ViewOptionsState) => {
  return viewOptionsState.translationHighlight;
}

export const getLabelsSettings = (viewOptionsState: ViewOptionsState) => {
  return viewOptionsState.labelSettings;
}

export const getSmallLabels = (viewOptionsState: ViewOptionsState) => {
  return viewOptionsState.smallLabels;
}

export const getShowUnsearchable = (viewOptionsState: ViewOptionsState) => {
  return viewOptionsState.showUnsearchable;
}
