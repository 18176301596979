<h1 mat-dialog-title>{{'dialog.save-errors.title' | translate}}</h1>
<div mat-dialog-content class="mat-typography">
  <p>{{'dialog.save-errors.intro' | translate}}</p>
  <mat-list *ngIf="error">
      <ng-container [ngSwitch]="error.status">
        <span [innerHTML]="'dialog.save-errors.status.' + error.status | translate: {node: (error.resolvedSubject | async)}"></span>
        <ng-container *ngSwitchCase="409">
          <div *ngFor="let user of (error.resolvedDetails | async)" class="indent">
            {{ user.name }} <span class="pm-fullname">{{ user.fullName}}</span>
          </div>
        </ng-container>
      </ng-container>
  </mat-list>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close="ok" cdkFocusInitial>{{'button.ok' | translate}}</button>
  <button mat-button mat-dialog-close="force" >{{'button.force' | translate}}</button>
</div>
