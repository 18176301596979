import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import {ColorChromeModule} from 'ngx-color/chrome';
import {ProcessManagerMaterialModule} from '../../material/material.module';
import {ColorPickerInputComponent} from './color-picker-input/color-picker-input.component';
import {FoldableDialogTitleComponent} from './foldable-dialog-title/foldable-dialog-title.component';
import {PasswordChangeComponent} from './password-change-component/password-change.component';
import {ResponsiveMenuComponent, ResponsiveMenuItemComponent} from './responsive-menu/responsive-menu.component';
import {PopupDialogComponent} from "./dialogs/popup-dialog/popup-dialog.component";
import {SaveWarningsDialogComponent} from "./dialogs/save-warnings-dialog/save-warnings-dialog.component";
import {CantOpenDialogComponent} from "./dialogs/cant-open-dialog/cant-open-dialog.component";
import {GenericErrorDialogComponent} from "./dialogs/generic-error-dialog/generic-error-dialog.component";
import {SaveErrorDialogComponent} from "./dialogs/save-error-dialog/save-error-dialog.component";

@NgModule({
  declarations: [
    PasswordChangeComponent, PopupDialogComponent, ColorPickerInputComponent, ResponsiveMenuComponent,
    ResponsiveMenuItemComponent, FoldableDialogTitleComponent, SaveWarningsDialogComponent, SaveErrorDialogComponent,
    CantOpenDialogComponent,
    GenericErrorDialogComponent
  ],
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, ProcessManagerMaterialModule, TranslateModule.forChild(),
    ColorChromeModule],
  exports: [PasswordChangeComponent, ColorPickerInputComponent, ResponsiveMenuComponent, ResponsiveMenuItemComponent,
    FoldableDialogTitleComponent, SaveWarningsDialogComponent, SaveErrorDialogComponent,
    CantOpenDialogComponent, GenericErrorDialogComponent]
})
export class SharedComponentsModule {
}
