import {HttpClient} from '@angular/common/http';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {UserService} from '@process-manager/pm-library';
import {Subscription} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {environment} from '../../../../environments/environment';
import {SearchElement} from '../../../shared/model/search.element';
import {NodeType} from '../../../shared/model/treeelement';
import {ReportService} from '../../../shared/services/report.service';

interface LinkCheckElement extends SearchElement {
  extraData: {
    url: NodeType; linkID: number;
  }
}

@Component({
  selector: 'pm-link-checker',
  templateUrl: './link-check.component.html',
  styleUrls: ['./link-check.component.css']
})
export class LinkCheckComponent implements OnInit, OnDestroy {
  folded = false;
  searchResultsSubscription: Subscription;
  loading = true;
  displayedColumns = ['label', 'path'];
  dataSource = new MatTableDataSource<SearchElement>();
  activeSearchElement: any = null;

  constructor(private userService: UserService, private http: HttpClient, private reportService: ReportService) {
  }

  ngOnInit() {
    const url = environment.api + this.userService.domain + '/links/check';
    this.searchResultsSubscription = this.http.get<LinkCheckElement[]>(url).pipe(finalize(() => this.loading = false))
      .subscribe(elements => this.dataSource.data = this.reportService.translateBackupElement(elements));
  }

  ngOnDestroy() {
    this.searchResultsSubscription.unsubscribe();
  }

  navigateToNode(searchElement: LinkCheckElement) {
    this.activeSearchElement = searchElement.id;
    this.reportService.navigate(searchElement);
  }
}
