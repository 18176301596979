import {Component, ElementRef, Inject, OnInit, Optional, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {UserService} from '@process-manager/pm-library';

import {defaultButtonsWithLinking, defaultTrumbowygOptions, JQueryTrumbowyg} from '../../shared/trumbowyg.directive';
import {Logout} from '../../state-management/actions/auth.actions';
import {AddLogbookEntry} from '../../state-management/actions/logbook-saving.actions';
import {AppState} from '../../state-management/reducers';
import {PromanLinkService} from '../process-tree/shared/proman-link.service';
import {LogbookEntry, LogbookService} from './shared/logbook.service';
import {
  PopupDialogComponent,
  PopupDialogData
} from "../../shared/components/dialogs/popup-dialog/popup-dialog.component";

const DEFAULT_ENTRY = '<p></p>';

@Component({
    selector: 'pm-logbook',
    templateUrl: './logbook-dialog.component.html',
    styleUrls: ['./logbook-dialog.component.css']
})
export class LogbookDialogComponent implements OnInit {
    @ViewChild('entryList', {read: ElementRef, static: true}) entryList: ElementRef;

    entries: LogbookEntry[] = [];
    newEntry: string = DEFAULT_ENTRY;

    readonly trumbowygOptions: JQueryTrumbowyg.Options = {
        ...defaultTrumbowygOptions,
        btns: [...defaultButtonsWithLinking(), ['fullscreen']]
    };

    user = this.userService.user;

    constructor(private logbookService: LogbookService, private userService: UserService,
                private dialogRef: MatDialogRef<LogbookDialogComponent>, private linkService: PromanLinkService,
                private translate: TranslateService, private dialog: MatDialog, private store: Store<AppState>,
                @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    }

    get showLogoutButton() {
        return !!this.data?.logoutOnAdd;
    }

    get isAdmin(): boolean {
        return !!this.user && this.user.roles.includes('admin');
    }

    get isAuthor(): boolean {
        return this.isAdmin || (!!this.user && this.user.roles.includes('edit'));
    }

    ngOnInit() {
        this.logbookService.getEntries().subscribe(newEntries => {
            this.entries = newEntries;
            setTimeout(() => this.scrollEntryListToBottom(), 0);
        });
    }

    onDelete(deleteEntry: LogbookEntry) {
        this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
            data: {
                title: 'dialog.sure',
                body: 'dialog.logbook.confirm-delete',
                actionLabel: 'button.remove',
                okLabel: 'button.cancel'
            }
        }).afterClosed().subscribe((result) => {
            if (result === 'action') {
                this.logbookService.deleteEntry(deleteEntry.id).subscribe(() => {
                    this.entries = this.entries.filter(entry => deleteEntry.id !== entry.id);
                });
            }
        })
    }

    onAddEntryClick(logoutOnClose = false) {
        if (this.newEntry && this.newEntry.length > 0) {
            this.logbookService.addEntry(this.newEntry).subscribe(entry => {
                this.entries.push({
                    ...entry,
                    author: this.user.username
                });
                setTimeout(() => this.scrollEntryListToBottom(), 0);
                this.store.dispatch(new AddLogbookEntry());
                if (logoutOnClose) {
                    this.store.dispatch(new Logout({domain: this.userService.domain}));
                    this.dialogRef.close();
                }
            });
            this.newEntry = DEFAULT_ENTRY;
        }
    }

    openLink(link: string) {
        if (this.linkService.openLink(link)) {
            this.dialogRef.close();
        }
    }

    private scrollEntryListToBottom() {
        const objDiv = this.entryList.nativeElement;
        objDiv.scrollTop = objDiv.scrollHeight;
    }
}
