import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserService} from '@process-manager/pm-library';
import {User} from '@process-manager/pm-library/lib/model/user';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {PromanLinkService} from '../../../../main/process-tree/shared/proman-link.service';
import {ActionWarning} from '../../../services/tree.service';
import {stripHtml} from '../../../styleExtractor';

const NODE_MAPPED_TO_USERS = 'nodes_mapped_to_users';

interface Resolved {
  resolvedDetails: Observable<any[]>,
  resolvedSubject: Observable<string>
}

@Component({
  selector: 'pm-save-warnings-dialog',
  templateUrl: './save-warnings-dialog.component.html',
  styleUrls: ['./save-warnings-dialog.component.css']
})
export class SaveWarningsDialogComponent implements OnInit {
  warnings: (ActionWarning & Resolved)[];

  users$ = this.userService.getUsersAndGroups().pipe(map(userAndGroups => userAndGroups.users), shareReplay());
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: ActionWarning[], private userService: UserService, private linkService: PromanLinkService) {
  }

  ngOnInit(): void {
    this.warnings = this.data?.map(this.getExtraDetails)
  }

  getExtraDetails = (warning: ActionWarning): ActionWarning & Resolved => {
    switch (warning.code) {
      case NODE_MAPPED_TO_USERS: {
        return {
          ...warning,
          resolvedSubject: this.linkService.getProcessDetailsFromUrlOrId(warning.subject)
            .pipe(map(details => `<a href="${details.url}">${stripHtml(details.html)}</a>`)),
          resolvedDetails: this.users$.pipe(map((users: User[]) =>
            users.filter(user => warning.details.includes(user.id)).map(user => ({
              id: user.id,
              name: user.username,
              fullName: [user.firstName, user.lastName].join(' ').trim(),
            }))
          ))
        }
      }
    }
  }
}
