<mat-toolbar color="accent">
  <mat-toolbar-row class="pm-main-toolbar">
    <span><button mat-icon-button (click)="onMenuClicked()" id="proman-sidenav-button"><mat-icon>menu</mat-icon></button></span>
    <button class="only-large proman-logo" mat-button (click)="showAbout()">
      <mat-icon svgIcon="proman-logo" class="pm-logo"></mat-icon>
    </button>

    <span><button matTooltip="{{'menu.toolbar.refresh-page' | translate}}" (click)="onReloadClicked()"
                  *ngIf="!(isLoading$ | async) && !(isTemplateTree$| async) && (isOnline$ | async)" mat-icon-button><mat-icon>refresh</mat-icon></button></span>
    <span class="progress-spinner-wrapper" *ngIf="isLoading$ | async">
      <mat-progress-spinner mode="indeterminate"
                            [diameter]="24"
                            [strokeWidth]="3"
                            [@spinner]></mat-progress-spinner>
    </span>
    <span  *ngIf="isOnline$ | async">
      <button matTooltip="{{'menu.toolbar.favorites' | translate}}" mat-icon-button *ngIf="!(isTemplateTree$| async)"
                  [matMenuTriggerFor]="favoriteMenu"><mat-icon>star</mat-icon></button>
    </span>
    <mat-menu #favoriteMenu="matMenu">
      <button mat-menu-item *ngFor="let favorite of favorites$ | async" (click)="onFavoriteClicked(favorite.id)">
        {{favorite.label}}
      </button>
    </mat-menu>
    <span *ngIf="isOnline$ | async">
      <button matTooltip="{{'menu.toolbar.search' | translate}}" mat-icon-button (click)="showSearchPanel()"
                  *ngIf="!(isTemplateTree$| async)">
        <mat-icon>search</mat-icon>
      </button>
    </span>

    <ng-container *ngIf="(isAuthor$ | async) && !(isTemplateTree$| async)">
        <span><button matTooltip="{{newProcessPageString | translate}}" mat-icon-button (click)="createClicked()">
          <mat-icon>note_add</mat-icon></button></span>
      <span><button matTooltip="{{'menu.toolbar.paint-format' | translate}}" mat-icon-button
                    [class.mat-elevation-z3]="formattingActive"
                    (click)="formatClicked()">
          <mat-icon>format_paint</mat-icon></button></span>
      <span><button id="pm-paste-button" matTooltip="{{'menu.toolbar.paste' | translate}}" mat-icon-button
                    (click)="onPasteClick()"><mat-icon>content_paste</mat-icon></button></span>
      <span><button matTooltip="{{'menu.toolbar.undo' | translate}}" mat-icon-button
                    [disabled]="!(hasUndo$ | async)" (click)="undo()"><mat-icon>undo</mat-icon></button></span>
      <span><button matTooltip="{{'menu.toolbar.redo' | translate}}" mat-icon-button
                    [disabled]="!(hasRedo$ | async)" (click)="redo()"><mat-icon>redo</mat-icon></button></span>
      <span><button *ngIf="!(isSaving$ | async)" matTooltip="{{'menu.toolbar.save' | translate}}" mat-icon-button
                    [disabled]="!(hasUndoNotTextEditing$ | async)" (click)="save()"><mat-icon>cloud_upload</mat-icon></button></span>
      <span class="progress-spinner-wrapper" *ngIf="isSaving$ | async">
        <mat-progress-spinner mode="indeterminate" [diameter]="24" [strokeWidth]="3" [@spinner]></mat-progress-spinner>
      </span>
    </ng-container>

    <span><button *ngIf="(isAuthor$ | async) && (templatesEnabled$ | async)"
                  matTooltip="{{'menu.toolbar.templates' | translate}}" mat-icon-button
                  (click)="toggleTemplates()" [class.mat-elevation-z3]="templatesShowing$ | async"><mat-icon>inventory_2</mat-icon></button>
      </span>
    <span><button *ngIf="(isTemplateTree$ | async)"
                  matTooltip="{{'menu.toolbar.templates.return' | translate}}" mat-icon-button
                  (click)="exitTemplate()"><mat-icon>close</mat-icon></button>
      </span>

    <span class="toolbar-push-right"></span>
    <pm-language-selector class="only-medium-and-large" [languages]="languages$ | async" [isTemplateTree]="isTemplateTree$ | async"
                          [currentLanguage]="currentLanguage | async" (languageChange)="selectLanguage($event)"
                          *ngIf="(languages$ | async)?.length > 1 && (isOnline$ | async)"></pm-language-selector>
    <pm-history-view></pm-history-view>
    <pm-view-options-menu [translationHighlight]="translationHighlight$ | async" [labelSettings]="labelsView$ | async" (labelChange)="labelsViewChange($event)"
                          (highlightChange)="highlightChange($event)" [showHighlightButton]="!(isTemplateTree$| async)"
                          (showUnsearchableChange)="showUnsearchableChange($event)" [showUnsearchable]="showUnsearchable$ | async"></pm-view-options-menu>
    <span><button class="only-medium-and-large" matTooltip="{{'dialog.logbook.title' | translate}}"
             mat-icon-button (click)="onLogbookClick()"  *ngIf="isOnline$ | async"><mat-icon>notes</mat-icon></button></span>
    <span><button class="only-medium-and-large" matTooltip="{{'menu.toolbar.fullscreen' | translate}}"
                  *ngIf="canFullscreen" mat-icon-button
                  (click)="onFullscreen()"><mat-icon>fullscreen</mat-icon></button></span>
    <span><button class="only-medium-and-large" matTooltip="{{'menu.toolbar.logoff' | translate}}" mat-icon-button
                  (click)="onLogout()"><mat-icon>exit_to_app</mat-icon></button></span>
    <span><button class="only-small" matTooltip="More..." mat-icon-button [matMenuTriggerFor]="smallDeviceMenu"><mat-icon>more_vert</mat-icon></button></span>
  </mat-toolbar-row>
  <mat-toolbar-row class="pm-bread-crumbs-toolbar">
    <pm-breadcrumbs [path]="path$ | async" [domain]="domain$ | async"></pm-breadcrumbs>
  </mat-toolbar-row>
</mat-toolbar>
<mat-menu #smallDeviceMenu="matMenu">
  <pm-language-selector [languages]="languages$ | async" [currentLanguage]="(currentLanguage | async)"
                        [isTemplateTree]="isTemplateTree$ | async" (languageChange)="selectLanguage($event)"
                        *ngIf="(languages$ | async)?.length > 1 && (isOnline$ | async)" (click)="$event.stopPropagation()">
  </pm-language-selector>
  <a mat-menu-item  *ngIf="isOnline$ | async" (click)="onLogbookClick()">{{'dialog.logbook.title' | translate}}</a>
  <button mat-menu-item *ngIf="canFullscreen"
          (click)="onFullscreen()">{{'menu.toolbar.fullscreen' | translate}}
  </button>
  <button mat-menu-item (click)="onLogout()">{{'menu.toolbar.logoff'
    | translate}}
  </button>
</mat-menu>
