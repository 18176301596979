import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Action, select, Store} from '@ngrx/store';
import {LiftedState, StoreDevtools} from '@ngrx/store-devtools';
import {TranslateService} from '@ngx-translate/core';
import {catchError, finalize, flatMap, map, take} from 'rxjs/operators';
import {ContactService} from '../../../services/contact.service';
import {ErrorAction} from '../../../../state-management/actions/tree.actions';
import {AppState, getTreeHistoryActions} from '../../../../state-management/reducers';
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";

const CONTACT_EMAIL = 'tech@process-manager.dk';
const DEBUG = false;

@Component({
  selector: 'pm-error-dialog',
  templateUrl: './generic-error-dialog.component.html',
  styleUrls: ['./generic-error-dialog.component.css']
})
export class GenericErrorDialogComponent implements OnInit {
  error: Error;
  action: Action;
  errorActionType: string;

  reporting = false;
  reported = false;
  saveHistory$ = this.store$.pipe(select(getTreeHistoryActions));
  showDebug = false;
  prettySaveHistory$ = this.saveHistory$.pipe(map(actions => actions.map(action => JSON.stringify(action))));
  hasSaveHistory$ = this.saveHistory$.pipe(map(actions => actions.length > 0));
  get message(): string {
    if(this.error instanceof HttpErrorResponse) {
      return `Http failure response for ${this.error.url}: ${this.error.status} ${HttpStatusCode[this.error.status]}`
    } else {
      return this.error.message;
    }
  }


  constructor(@Inject(MAT_DIALOG_DATA) errorAction: ErrorAction, private store$: Store<AppState>,
              private contactService: ContactService, private translate: TranslateService, private storeDevtools: StoreDevtools) {
    this.error = errorAction.payload.error;
    this.action = errorAction.payload.action;
    this.errorActionType = errorAction.type;
  }

  sendError() {
    if (DEBUG) {
      this.storeDevtools.liftedState.pipe(take(1)).subscribe(liftedState => console.log(this.stateSanitizer(liftedState)));
    } else {
      this.reporting = true;
      this.storeDevtools.liftedState.pipe(take(1), flatMap(liftedState => this.contactService.reportError({
        error: this.error,
        action: this.action,
        liftedState: this.stateSanitizer(liftedState)
      })), finalize(() => this.reporting = false),
        catchError(err => {
        alert(this.translate.instant('dialog.error.report.error', {email: CONTACT_EMAIL}));
        throw(err);
      })).subscribe(() => this.reported = true);
    }
  }

  ngOnInit() {
  }

  private stateSanitizer(liftedState: LiftedState): any {
    const historyRemove = state => {
      return !!state?.tree ? {
        ...state,
        tree: {
          ...state.tree,
          history: undefined
        }
      } : state
    };

    return {
      ...liftedState,
      committedState: historyRemove(liftedState.committedState),
      computedStates: liftedState.computedStates.map(computedState => ({
        ...computedState,
        state: historyRemove(computedState.state)
      }))
    }
  }

}
