import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {UserService} from '@process-manager/pm-library';
import {User} from '@process-manager/pm-library/lib/model/user';
import {Observable} from 'rxjs';
import {map, shareReplay} from 'rxjs/operators';
import {PromanLinkService} from '../../../../main/process-tree/shared/proman-link.service';
import {stripHtml} from '../../../styleExtractor';
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";

interface Resolved {
  resolvedDetails: Observable<any[]>,
  resolvedSubject: Observable<string>
}

@Component({
  selector: 'pm-save-warnings-dialog',
  templateUrl: './save-error-dialog.component.html',
  styleUrls: ['./save-error-dialog.component.css']
})
export class SaveErrorDialogComponent implements OnInit {
  error: (HttpErrorResponse & Resolved);

  users$ = this.userService.getUsersAndGroups().pipe(map(userAndGroups => userAndGroups.users), shareReplay());
  constructor(@Optional() @Inject(MAT_DIALOG_DATA) private data: HttpErrorResponse, private userService: UserService, private linkService: PromanLinkService) {
  }

  ngOnInit(): void {
    this.error = this.getExtraDetails(this.data)
  }

  getExtraDetails = (error: HttpErrorResponse): HttpErrorResponse & Resolved => {
    switch (error?.status) {
      case HttpStatusCode.Conflict: {
        return {
          ...error,
          resolvedSubject: this.linkService.getProcessDetailsFromUrlOrId(error.error.details.target)
            .pipe(map(details => `<a href="${details.url}">${stripHtml(details.html)}</a>`)),
          resolvedDetails: this.users$.pipe(map((users: User[]) =>
            users.filter(user => error.error.details.users.includes(user.id)).map(user => ({
              id: user.id,
              name: user.username,
              fullName: [user.firstName, user.lastName].join(' ').trim(),
            }))
          ))
        }
      }
    }
  }
}
