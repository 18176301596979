<!--suppress AngularUndefinedBinding -->
<div (pmClickedOutside)="this.deactivate()" [clickedOutsideActive]="isActive">
<div class="process" [style.width]="width + 'px'" [style.min-height]="height + 'px'" [ngClass]="shape"
     [class.active]="isActive" [class.editText]="isEditingText" [class.highlight]="context?.highlights?.text"
     [class.expandable]="hasArrow" [class.pm-no-select]="!isEditingText" #processWrapper
     (contextmenu)="contextMenu($event)"
     (click)="processClicked($event)"
     (pm-dragEnter)="onMouseEnter()" (touchstart)="onMouseEnter()" (mouseleave)="onMouseLeave()"
     (touchend)="onMouseLeave()" (pm-resize)="onResizeMove($event.detail)"
     (pm-resizeend)="onResizeEnd()" [attr.data-element-type]="context.nodeType"
     [attr.data-element-template-id]="context.templateId" [attr.data-element-template-version]="context.templateVersion" [attr.data-element-id]="context.id"
     [matTooltip]="getOwner(isTouching$ | async)" [matTooltipShowDelay]="500">
  <pm-shape [style]="context.style" [isOpen]="context.isOpen && context.nodeType !== 'page' && !isActive" [width]="width" [height]="measuredHeight" [highlight]="context?.highlights?.text"></pm-shape>
  <div class="process-wrapper" [style.padding]="paddingString">
    <div class="pm-user-content" [class.isOpen]="context.isOpen && context.nodeType !== 'page' && !isActive" [pmTrumbowyg]="trumbowygOptions" (textUpdate)="textUpdate()" (pmLinkClick)="linkClicked($event)"
         [enabled]="isEditingText" [offsetElement]="processWrapper" [innerHtml]="context.label | unsafeHtml"></div>
  </div>
  <mat-icon *ngIf="hasArrow && !isActive" class="pm-open-icon pm-remove-when-dragging" aria-label="open process">arrow_drop_down
  </mat-icon>
  <!-- TODO: Replace icon -->
  <mat-icon *ngIf="isActive && context.writeable && isAuthor" svgIcon="proman-resize-handle" class="pm-resize-handle pm-remove-when-dragging"></mat-icon>
  <pm-menu-trigger-overlay (click)="toggleMenu($event)"></pm-menu-trigger-overlay>
</div>
<div *ngIf="isActive && context.autoTranslated.text" class="mat-small pm-auto-translate-notice">{{'menu.link.auto-translated' | translate}}</div>
<div *ngIf="context.nodeType != 'page'" [class.pm-dropzone-under]="dragDropActive && context.writeable" [class.pm-dropzone-dead]="!dragDropActive" [attr.data-element-id]="context.id" data-element-drop-type="under"
     [attr.data-element-type]="context.nodeType">
  <div class="pm-dropzone-highlight"></div>
</div>
<pm-options-overlay [context]="context"  [contextElement]="processDiv"
                    *ngIf="isActive" (toggleEdit)="toggleEdit($event)"></pm-options-overlay>

<div class="overlays">
  <pm-labels-overlay [context]="context"></pm-labels-overlay>
  <pm-link-overlay *ngIf="hasLinks()" [node]="context" (linkClicked)="linkClicked($event)"
                   [highlightInfo]="context?.highlights?.info" (contextmenu)="toggleMenu($event)"
  ></pm-link-overlay>
</div>

<pm-link-menu [node]="context" *ngIf="hasLinks()" [open]="isActive && !isEditingText" [isAuthor]="isAuthor"
              (linkClicked)="linkClicked($event)"></pm-link-menu>
<pm-bullet-list *ngIf="context.nodeType != 'page'" class="bullet-wrapper" [isAuthor]="isAuthor" [isTemplateRoot]="isTemplateRoot"
                [bullets]="context.bullets" [dragDropActive]="dragDropActive && context.writeable"
                (activationChange)="activationChange.emit($event)" (elementClick)="elementClick.emit($event)"
                (linkClick)="linkClick.emit($event)" (resized)="resized.emit($event)" (updated)="updated.emit($event)">
</pm-bullet-list>
</div>
