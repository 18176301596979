/* eslint-disable @typescript-eslint/member-ordering */
import {Component, OnDestroy, OnInit, Optional} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSelectChange} from '@angular/material/select';
import {Store} from '@ngrx/store';
import {UserService} from '@process-manager/pm-library';
import * as Immutable from 'immutable';
import {Observable} from 'rxjs';
import {finalize, map, take} from 'rxjs/operators';

import {Label} from '../../shared/model/label';
import {LabelService} from '../../shared/services/label.service';
import {PmValidators} from '../../shared/validators/pm-validators';
import {ClearHistory, LabelCreated, LabelDeleted, LabelUpdated} from '../../state-management/actions/tree.actions';
import {
  AppState,
  getLabelIsUsedInFutureHistory,
  getLabelIsUsedInPastHistory,
  getTreeLabelArray,
  getTreeLabels
} from '../../state-management/reducers';
import {
  ConfirmDialogComponent,
  ConfirmDialogOptions,
  ConfirmDialogResult
} from '../confirm-navigation/confirm-dialog.component';
import {
  PopupDialogComponent,
  PopupDialogData
} from "../../shared/components/dialogs/popup-dialog/popup-dialog.component";

const NEW_LABEL: Label = {
  id: -1,
  name: '',
  color: '#FFFFFF',
  isTrial: false,
  extension: false
};

@Component({
  selector: 'pm-label-admin-dialog',
  templateUrl: './label-admin-dialog.component.html',
  styleUrls: ['./label-admin-dialog.component.css']
})
export class LabelAdminDialogComponent implements OnInit, OnDestroy {
  readonly NEW_LABEL_SELECT = NEW_LABEL.id;

  private labels$ = this.store$.select(getTreeLabels);
  labelAsArray$ = this.store$.select(getTreeLabelArray);
  labelNames$ = this.labelAsArray$.pipe(map(labels => labels.map(label => label.name)));

  private submitting: boolean;

  labels: Immutable.Map<number, Label> = Immutable.Map();
  showVendorFeatures = this.userService.getSiteSettings()?.vendorFeatures || false;
  labelSubscription = this.labels$.subscribe(labels => this.labels = labels);
  originalName$: Observable<string> = this.labels$.pipe(
    map(labels => labels.find(label => label.id === this.labelForm.get('id').value)?.name));

  labelForm = this.fb.group({
    id: [NEW_LABEL.id, [Validators.required]],
    name: [NEW_LABEL.name, [Validators.required, Validators.minLength(1), Validators.maxLength(30)],
      [PmValidators.asyncUniqueNameValidator(this.labelNames$, this.originalName$)]],
    color: [NEW_LABEL.color],
    labelType: ['none'],
  })

  constructor(private fb: FormBuilder, private userService: UserService, private store$: Store<AppState>,
    private matDialog: MatDialog, private labelService: LabelService,
    @Optional() private dialogRef: MatDialogRef<LabelAdminDialogComponent>) {
  }

  get isNew() {
    return this.labelForm.get('id').value === NEW_LABEL.id;
  }

  get name() {
    return this.labelForm.get('name');
  }

  ngOnInit(): void {
  }

  labelSelectionChange($event: MatSelectChange) {
    if (!$event.value || $event.value === this.NEW_LABEL_SELECT) {
      this.labelForm.setValue(this.labelAsForm({...NEW_LABEL}));
    } else {
      this.labelForm.reset(this.labelAsForm({...this.labels.get($event.value)}));
    }
  }

  submit() {
    let call: Observable<Label>;
    const label = this.formAsLabel;
    if (this.isNew) {
      call = this.labelService.createLabel(label);
    } else {
      call = this.labelService.updateLabel(label);
    }

    this.submitting = true;
    call.pipe(finalize(() => this.submitting = false)).subscribe((storedLabel) => {
      if (this.isNew) {
        this.store$.dispatch(new LabelCreated({label: storedLabel}));
      } else {
        this.store$.dispatch(new LabelUpdated({label: storedLabel}));
      }

      this.dialogRef.close();
    })
  }

  delete() {
    this.store$.select(getLabelIsUsedInPastHistory(this.formAsLabel.id)).pipe(take(1)).subscribe(result => {
      if (result) {
        this.matDialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
          data: {
            body: 'dialog.cannot-delete-used-label',
            title: 'dialog.cannot-delete-used-label.title',
          }
        })
      } else {
        this.matDialog.open<ConfirmDialogComponent, ConfirmDialogOptions, ConfirmDialogResult>(ConfirmDialogComponent, {
          data: {
            body: 'dialog.confirm-delete-label',
            title: 'dialog.confirm-delete-label.title',
            bodyTranslationArguments: {
              ['labelName']: this.formAsLabel.name
            },
            hideSaveButton: true,
            continueButton: 'button.delete'
          }
        }).afterClosed().subscribe(result => {
          if(result === 'continue') {
            this.store$.select(getLabelIsUsedInFutureHistory(this.formAsLabel.id)).subscribe(isUsed => {
              if(isUsed) {
                this.store$.dispatch(new ClearHistory({onlyFuture: true}))
              }
            })

            this.labelService.deleteLabel(this.formAsLabel).subscribe(() => {});
            this.store$.dispatch(new LabelDeleted({label: this.formAsLabel}));
            this.labelForm.reset(NEW_LABEL);
          }
        })
      }
    })
  }

  ngOnDestroy(): void {
    this.labelSubscription.unsubscribe();
  }

  private get formAsLabel(): Label {
    const formValue = this.labelForm.value;
    return {
      id: formValue.id,
      color: formValue.color,
      name: formValue.name,
      isTrial: formValue.labelType === 'trial',
      extension: formValue.labelType === 'extension'
    }
  }

  private getLabelType(label: Label): 'none'|'trial'|'extension' {
    if (label.extension) {
      return 'extension'
    } else if (label.isTrial) {
      return 'trial'
    } else {
      return 'none'
    }
  }

  private labelAsForm(label: Label): any {
    return {
      id: label.id,
      color: label.color,
      name: label.name,
      labelType: this.getLabelType(label),
    }
  }

}
