import {HttpEventType} from '@angular/common/http';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {v1} from 'uuid';

import {ImageClearAll, SetLogo, SetWatermark} from '../../../state-management/actions/image.actions';
import {AddResource, RemoveResource, UpdateResource} from '../../../state-management/actions/tree.actions';
import {
  AppState,
  getTreeAllRootLogoSettings,
  getTreeAllRootWatermarkSettings,
  getTreeRoot
} from '../../../state-management/reducers';
import {
  BaseImageSettings,
  LogoSettings,
  Position,
  WatermarkSettings
} from '../../../state-management/reducers/tree.reducer';
import {PromanLinkService} from '../../process-tree/shared/proman-link.service';
import {
  PopupDialogComponent,
  PopupDialogData
} from "../../../shared/components/dialogs/popup-dialog/popup-dialog.component";

interface UploadData {
  pending: boolean;
  progress: number;
  filename: string;
  token: string;
  preSignedUrl: string;
}

const EMPTY_UPLOAD_DATA: UploadData = {
  pending: false,
  progress: 0,
  filename: '',
  token: '',
  preSignedUrl: '',
};

const EMPTY_BASE_IMAGE = {
  inherited: false,
  filename: '',
  source: '',
  maintainAspectRatio: true,
  preSignedUrl: '',
};


function getWatermarkServerSettings(settings: WatermarkSettings): { [key: string]: any } {
  return {
    alpha: settings.alpha,
    ratio: settings.ratio
  };
}

function getLogoServerSettings(settings: LogoSettings): { [key: string]: any } {
  return {
    position: settings.position
  };
}

/**
 * A "soft" equals. It doesn't need to be 100% correct, just enough to catch most unchanged changes.
 * @param {WatermarkSettings} a
 * @param {WatermarkSettings} b
 * @returns {boolean}
 */
function watermarksEqual(a: WatermarkSettings, b: WatermarkSettings) {
  if (a === b) {
    return true;
  }

  return a.inherited === b.inherited && a.filename === b.filename && a.alpha === b.alpha && a.ratio === b.ratio;
}

/**
 * A "soft" equals. It doesn't need to be 100% correct, just enough to catch most unchanged changes.
 * @param {LogoSettings} a
 * @param {LogoSettings} b
 * @returns {boolean}
 */
function logosEqual(a: LogoSettings, b: LogoSettings) {
  if (a === b) {
    return true;
  }

  return a.inherited === b.inherited && a.filename === b.filename && a.position === b.position;
}

@Component({
  selector: 'pm-logo-dialog',
  templateUrl: './logo-dialog.component.html',
  styleUrls: ['./logo-dialog.component.css']
})
export class LogoDialogComponent implements OnInit, OnDestroy {
  logoUploadData: UploadData = {...EMPTY_UPLOAD_DATA};
  logoEnabled: boolean;
  activeLogoSetting: LogoSettings;
  watermarkUploadData: UploadData = {...EMPTY_UPLOAD_DATA};
  watermarkEnabled: boolean;
  activeWatermarkSetting: WatermarkSettings;

  private chooseFileLabel;
  private rootId: string;
  private idSubscription = this.store$.select((getTreeRoot)).subscribe(root => this.rootId = root && root.id || '');
  private watermarkSettings: WatermarkSettings[];
  private logoSettings: LogoSettings[];
  private logoSettingsSubscription = this.store$.select(getTreeAllRootLogoSettings).subscribe(logoSettings => {
    this.logoSettings = logoSettings;
    this.logoEnabled = logoSettings.some(logoSetting => !logoSetting.inherited);
  });

  private watermarkSettingsSubscription = this.store$.select(getTreeAllRootWatermarkSettings)
    .subscribe(watermarkSettings => {
      this.watermarkSettings = watermarkSettings;
      this.watermarkEnabled = watermarkSettings.some(watermarkSetting => !watermarkSetting.inherited);
    });

  constructor(public dialogRef: MatDialogRef<LogoDialogComponent>, private linkService: PromanLinkService,
    private dialog: MatDialog, private store$: Store<AppState>, private translateService: TranslateService,
    private changeDetectorRef: ChangeDetectorRef) {
  }

  get logoPosition(): Position {
    return this.activeLogoSetting && this.activeLogoSetting.position || 'topright';
  }

  set logoPosition(position: Position) {
    this.activeLogoSetting = {
      ...this.activeLogoSetting,
      position: position
    };
    this.onLogoChange();
  }

  get logoFilename(): string {
    return this.logoUploadData && this.logoUploadData.filename || this.activeLogoSetting &&
      this.activeLogoSetting.filename || this.chooseFileLabel;
  }

  get watermarkOpacity(): number {
    return this.activeWatermarkSetting && this.activeWatermarkSetting.alpha * 100 || 30;
  }

  set watermarkOpacity(opacity: number) {
    this.activeWatermarkSetting = {
      ...this.activeWatermarkSetting,
      alpha: opacity / 100
    };
    this.onWatermarkChange();
  }

  get watermarkSize(): number {
    return this.activeWatermarkSetting && this.activeWatermarkSetting.ratio * 100 || 100;
  }

  set watermarkSize(size: number) {
    this.activeWatermarkSetting = {
      ...this.activeWatermarkSetting,
      ratio: size / 100
    };
    this.onWatermarkChange();
  }

  get watermarkFilename(): string {
    return this.watermarkUploadData && this.watermarkUploadData.filename || this.activeWatermarkSetting &&
      this.activeWatermarkSetting.filename || this.chooseFileLabel;
  }

  formatLabel(value: number | null) {
    return value && value + '%' || '0%';
  }

  ngOnInit() {
    this.activeLogoSetting = {...(this.logoSettings && this.logoSettings[0])};
    this.activeWatermarkSetting = {...(this.watermarkSettings && this.watermarkSettings[0])};

    this.translateService.get('dialog.logo.choose-file').subscribe(label => this.chooseFileLabel = label);
    this.dialogRef.afterClosed().subscribe(() => {
      this.store$.dispatch(new ImageClearAll());
    })
  }

  ngOnDestroy() {
    this.idSubscription.unsubscribe();
    this.logoSettingsSubscription.unsubscribe();
    this.watermarkSettingsSubscription.unsubscribe();
  }

  onLogoInheritChange($event: MatCheckboxChange) {
    if (!$event.checked) {
      this.logoUploadData = {...EMPTY_UPLOAD_DATA};
    }

    this.activeLogoSetting = this.updateSettings($event.checked, this.logoSettings, {
      ...EMPTY_BASE_IMAGE,
      position: 'topright',
      scale: false
    }, this.activeLogoSetting);
    this.onLogoChange();
  }

  onWatermarkInheritChange($event: MatCheckboxChange) {
    if (!$event.checked) {
      this.watermarkUploadData = {...EMPTY_UPLOAD_DATA};
    }

    this.activeWatermarkSetting = this.updateSettings($event.checked, this.watermarkSettings, {
      ...EMPTY_BASE_IMAGE,
      ratio: 1.0,
      alpha: 0.3
    }, this.activeWatermarkSetting);
    this.onWatermarkChange();
  }

  handleLogoUpload($event) {
    this.handleUploads($event, true);
  }

  handleWatermarkUpload($event) {
    this.handleUploads($event, false);
  }

  handleUploads($event: Event, isLogo: boolean) {
    const uploadData = isLogo ? this.logoUploadData : this.watermarkUploadData;
    const files: FileList = ($event.target as HTMLInputElement).files;
    if (files.length > 0) {
      uploadData.progress = 0;
      uploadData.pending = true;
      uploadData.filename = files[0].name;
    }
    this.linkService.uploadFile(files[0]).subscribe(event => {
      switch (event.type) {
        case HttpEventType.UploadProgress:
          uploadData.progress = (event.loaded * 100) / event.total;
          break;
        case HttpEventType.Response:
          uploadData.pending = false;
          uploadData.token = event.body.token;
          uploadData.preSignedUrl = event.body.preSignedUrl;
          if (isLogo) {
            this.activeLogoSetting = {
              ...this.activeLogoSetting,
              filename: uploadData.filename,
              source: event.body.preSignedUrl
            }
          } else {
            this.activeWatermarkSetting = {
              ...this.activeWatermarkSetting,
              filename: uploadData.filename,
              source: event.body.preSignedUrl
            }
          }
          isLogo ? this.onLogoChange() : this.onWatermarkChange();
      }
    }, error => {
      uploadData.pending = false;
      uploadData.filename = '';
      console.log('Something went wrong', error);
      this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
        data: {
          body: 'dialog.logo.upload-error'
        }
      });
    });
  }

  onLogoChange(): void {
    this.changeDetectorRef.markForCheck();
    this.store$.dispatch(new SetLogo({logo: this.activeLogoSetting}));
  }

  onWatermarkChange(): void {
    this.changeDetectorRef.markForCheck();
    this.store$.dispatch(new SetWatermark({watermark: this.activeWatermarkSetting}));
  }

  closeDialog(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.logoUploadData.pending || this.watermarkUploadData.pending) {
      this.dialog.open<PopupDialogComponent, PopupDialogData>(PopupDialogComponent, {
        data: {
          title: 'dialog.logo.please-wait.title',
          body: 'dialog.logo.please-wait'
        }
      });
      return;
    }

    const updateResources = <T extends BaseImageSettings>(settings: T[], activeSetting: T, uploadData: UploadData,
      type: 'logo' | 'watermark', statusFn: (T) => { [key: string]: any }, equalsFn: (a: T, b: T) => boolean) => {
      const oldResource = settings.find(setting => !setting.inherited);
      if (!activeSetting || activeSetting.inherited === true) {
        this.removeResource(oldResource);
      } else if (!!uploadData && !!uploadData.filename) {
        this.addResource(oldResource, type, statusFn(activeSetting), uploadData);
      } else if (!!oldResource && !!oldResource.link) {
        if (!!activeSetting && !equalsFn(activeSetting, (settings && settings[0]))) {
          this.updateResource(oldResource, type, statusFn(activeSetting));
        }
      }
    }

    updateResources(this.watermarkSettings, this.activeWatermarkSetting, this.watermarkUploadData, 'watermark',
      getWatermarkServerSettings, watermarksEqual);
    updateResources(this.logoSettings, this.activeLogoSetting, this.logoUploadData, 'logo', getLogoServerSettings,
      logosEqual);

    this.closeDialog();
  }

  private updateSettings<T extends BaseImageSettings>(active: boolean, settings: T[], defaultSetting: T,
    oldActiveSetting: T) {
    let activeSetting: T;
    if (!active) {
      activeSetting = settings.find(setting => setting.inherited) || null;
    } else {
      const newSetting = settings.find(setting => !setting.inherited) || oldActiveSetting;
      if (!newSetting || Object.keys(newSetting).length === 0) {
        activeSetting = {
          ...defaultSetting
        };
      } else {
        activeSetting = {
          ...newSetting,
          inherited: false
        };
      }
    }
    return activeSetting;
  }

  private addResource(oldResource, imageType: 'watermark' | 'logo', settings: { [key: string]: string },
    uploadData: UploadData) {
    this.store$.dispatch(new AddResource({
      elementId: this.rootId,
      resourceType: 'upload',
      replaceOldResource: !!oldResource && oldResource.link && oldResource.link.id || undefined,
      newLinkId: v1(),
      data: {
        filename: uploadData.filename,
        subtype: imageType,
        token: uploadData.token,
        tempSource: uploadData.preSignedUrl,
        settings: settings
      }
    }));
  }

  private updateResource(oldResource, imageType: 'watermark' | 'logo', settings: { [key: string]: string }) {
    this.store$.dispatch(new UpdateResource({
      elementId: this.rootId,
      resourceType: 'upload',
      data: {
        settings: settings,
        id: oldResource.link.id,
        subtype: imageType
      }
    }))
  }

  private removeResource(oldResource) {
    if (!!oldResource && !!oldResource.link) {
      this.store$.dispatch(new RemoveResource({
        resourceId: oldResource.link.id,
        resourceType: 'upload',
        elementId: this.rootId
      }));
    }
  }
}
