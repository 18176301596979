import {Action} from '@ngrx/store';
import {HighlightSelectionType} from '../reducers';
import {LabelSettings} from '../reducers/view-options.reducer';

export enum ViewOptionActionTypes {
  MARK_TRANSLATION_CHANGE = '[View Options] Translation mark change',
  LABEL_SETTINGS_CHANGE = '[View Options] Label settings change',
  LABEL_SIZE_CHANGE = '[View Options] Label size change',
  NOT_SEARCHABLE_CHANGE = '[View Options] Not searchable change',
}

export class MarkTranslationChange implements Action {
  readonly type = ViewOptionActionTypes.MARK_TRANSLATION_CHANGE;

  constructor(public payload: {type: HighlightSelectionType, store?: boolean}) {
  }
}

export class LabelSettingsChange implements Action {
  readonly type = ViewOptionActionTypes.LABEL_SETTINGS_CHANGE;

  constructor(public payload: {settings: LabelSettings, store?: boolean}) {
  }
}

export class ToggleLabelSize implements Action {
  readonly type = ViewOptionActionTypes.LABEL_SIZE_CHANGE;

  constructor(public payload?: {force?: boolean}) {
  }
}

export class NotSearchableChange implements Action {
  readonly type = ViewOptionActionTypes.NOT_SEARCHABLE_CHANGE;

  constructor(public payload: {show: boolean, store?: boolean}) {
  }
}

export type ViewOptionActions = MarkTranslationChange | LabelSettingsChange | ToggleLabelSize | NotSearchableChange
