import {Injectable, OnDestroy} from '@angular/core';
import {Store} from '@ngrx/store';
import {TranslateService} from '@ngx-translate/core';
import {AppConfig} from '../../app.config';
import {ActivateElement} from '../../state-management/actions/tree.actions';
import {getAuthUserIsPublic} from '../../state-management/reducers';
import {SearchElement} from '../model/search.element';
import {CustomerTreeBuilder} from '../tree-building/customer-tree-builder.service';
import {NavigationService} from './navigation.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService implements OnDestroy {
  isPublic: boolean;
  isPublicSubscription = this.store$.select(getAuthUserIsPublic).subscribe(isPublic => this.isPublic = isPublic);

  ngOnDestroy() {
    this.isPublicSubscription.unsubscribe();
  }

  translateBackupElement = <T extends SearchElement>(input: T[]): T[] => (input ?? []).map((element: T) => ({
    ...element,
    path: element.path.split('|').map(pathElement => pathElement === CustomerTreeBuilder.PM_BACKUP_NODE ?
      this.translate.instant('tree-builder.backup.node') : pathElement).join('|')
  }));

  navigate(searchElement: SearchElement) {
    this.appConfig.highlightNext = true;
    let promise: Promise<any>;
    if(['page','mainpage'].includes(searchElement.type)) {
      promise = this.navigationService.navigateToNode(searchElement.parent, this.isPublic).then(() =>
        this.store$.dispatch(new ActivateElement({ id: searchElement.id.toString(10), activationType: 'highlight' })));
    } else {
      promise = this.navigationService.navigateToNode(searchElement.id, this.isPublic);
    }
    promise.catch(err => console.warn('Could not navigate to node: ', err));
  }


  constructor(private appConfig: AppConfig, private navigationService: NavigationService, private store$: Store, private translate: TranslateService) { }
}
