import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import * as Immutable from 'immutable';
import {Bullet} from '../model/bullet';
import {Label} from '../model/label';
import {Link} from '../model/link';
import {Process, Size} from '../model/process';
import {Style} from '../model/style';
import {PathElement, Tree} from '../model/tree';
import {Permission, Permissions, TreeElement} from '../model/treeelement';
import {TreeBuilderInterface} from './tree-builder';

const lowercaseKeys = obj =>
  Object.keys(obj).reduce((acc, key) => {
    acc[key.toLowerCase()] = obj[key];
    return acc;
  }, {});

@Injectable()
export class CustomerTreeBuilder implements TreeBuilderInterface {
  public static readonly PM_BACKUP_NODE = '____PM_BACKUP____';
  private userIsPublic = false;

  constructor(private translate: TranslateService) {
  }

  build(json: any, extraOptions: {userIsPublic: boolean}): Tree {
    this.userIsPublic = extraOptions.userIsPublic;
    const [root, nodes, links] = this.buildNodes(this.buildStyles(json), json);

    return {
      source: 'customer',
      path: this.buildPath(json),
      localPath: [],
      root: root.id,
      labels: Immutable.Map(this.buildLabels(json)),
      nodes: Immutable.Map(nodes),
      links: Immutable.Map(links)
    };
  }

  private buildLinks(nodeJson: any, links: Map<string, Link>): string[] {
    const linkIds: string[] = [];
    if (Array.isArray(nodeJson.links)) {
      for (const jsonLink of nodeJson.links) {
        const link = this.buildLink(jsonLink);
        links.set(link.id, link);
        linkIds.push(link.id);
      }
    } else if (nodeJson.links) {
      const link = this.buildLink(nodeJson.links);
      links.set(link.id, this.buildLink(nodeJson.links));
      linkIds.push(link.id);
    }

    return linkIds;
  }

  private buildLink(link: any): Link {
    if (link) {
      return {
        id: link.uuid || String(link.id || link.ID),
        serverId: link.id || link.ID,
        label: link.name,
        action: link.action,
        linkType: link.type,
        defLink: link.defLink,
        adopted: link.adopted,
        settings: link.settings,
        color: link.color
      }
    }

    return null;
  }

  private buildStyles(json: any): Map<string, Style> {
    const styles = new Map<string, Style>();

    for (const style of json.style) {
      const id = String(style.id || style.ID);
      styles.set(id, {
        id: id,
        serverId: style.id || style.ID,
        lineColor: style.lineColor,
        bgColor: style.bgColor,
        shape: style.shape
      });
    }

    return styles;
  }

  private buildLabels(json: any): Map<number, Label> {
    const labels = new Map<number, Label>();

    for (const label of json.labels) {
      const id = Number(label.id || label.ID);
      labels.set(id, {
        id: id,
        name: label.name,
        color: label.color,
        isTrial: label.isTrial,
        extension: label.extension || false
      });
    }

    return labels;
  }

  private buildNodes(styles: Map<string, Style>, json: any): [Process, Map<string, TreeElement>, Map<string, Link>] {
    const nodes = new Map<string, TreeElement>();
    const links = new Map<string, Link>();
    const topLabels = json.treeLabels;
    return [this.buildProcess(nodes, links, styles, json.node, null, topLabels), nodes, links];
  }

  private buildProcess(nodes: Map<string, TreeElement>, links: Map<string, Link>, styles: Map<string, Style>,
                       nodeJson: any, parentId: string, parentLabels: number[]): Process {
    const style: Style = styles.get(String(nodeJson.style));
    const childSize: Size = new Size(nodeJson.childwidthratio || nodeJson.childWidthRatio, nodeJson.childheightratio || nodeJson.childHeightRatio );

    let owner = null;
    if(nodeJson.owner && (!nodeJson.owner.inherited || !parentId)) {
      owner =  nodeJson.owner.email;
    }

    const [hasUnloadedChildren, builtProcesses] = this.buildProcesses(nodes, links, styles, nodeJson, []);
    const processLinks = this.buildLinks(nodeJson, links);
    const process: Process = {
      ...this.buildElement(nodeJson, parentId, owner, style, processLinks, parentLabels),
      childSize: childSize,
      nodes: builtProcesses,
      bullets: this.buildBullets(nodes, links, styles, nodeJson),
      hasUnloadedChildren: hasUnloadedChildren,
    };

    nodes.set(process.id, process);
    return process;
  }

  private buildProcesses(nodes: Map<string, TreeElement>, links: Map<string, Link>, styles: Map<string, Style>,
                         nodeJson: any, parentLabels: number[]): [boolean, string[]] {
    const processes: string[] = [];
    let hasUnloadedChildren = false;
    if (Array.isArray(nodeJson.node)) {
      for (const childNode of nodeJson.node) {
        processes.push(this.buildProcess(nodes, links, styles, childNode, String(nodeJson.id || nodeJson.ID), parentLabels).id);
      }
    } else if (nodeJson.unloadedChildren || (nodeJson.node && nodeJson.node.id === 0)) {
      hasUnloadedChildren = true;
    }
    return [hasUnloadedChildren, processes];
  }

  private buildPermissions(nodeJson: any): Permissions {
    if (nodeJson.permissions) {
      const permissionArray: Permission[] = nodeJson.permissions.perm.map((value) => ({
        id: value.id,
        writeable: !!value.writeable,
        group: !!value.isGroup
      } as Permission));

      return {
        subjects: permissionArray,
        readAll: !!nodeJson.permissions.readall,
        writeAll: !!nodeJson.permissions.writeall
      };
    }
  }

  private buildBullets(nodes: Map<string, TreeElement>, links: Map<string, Link>, styles: Map<string, Style>,
                       nodeJson: any): string[] {
    const bullets: string[] = [];
    if (nodeJson.bullet) {
      for (const bullet of nodeJson.bullet) {
        bullets.push(this.buildBullet(nodes, links, styles, bullet, '', String(nodeJson.id || nodeJson.ID)));
      }
    }
    return bullets;
  }

  private buildBullet(nodes: Map<string, TreeElement>, links: Map<string, Link>, styles: Map<string, Style>,
                      bullet: any, currentOwner: string, parentId: string): string {
    const owner = bullet.owner && bullet.owner.email || currentOwner;
    const bulletLinks = this.buildLinks(bullet, links);
    const style = styles.get(String(bullet.style));
    const builtBullet: Bullet = this.buildElement(bullet, parentId, owner, style, bulletLinks);
    nodes.set(builtBullet.id, builtBullet);
    return builtBullet.id;
  }

  private buildElement(json: any, parentId: string, owner, style: Style, links: string[], parentLabels: number[] = []): TreeElement {
    json = lowercaseKeys(json);

    return {
      id: json.uuid || String(json.id),
      serverId: json.id,
      parentId: parentId,
      label: json.label || json.text || this.getBackupText(!!json.backup),
      info: json.info,
      extendedInfo: json.extendedinfo || '',
      infoColor: json.infocolor,
      owner: owner,
      style: style,
      type: json.type || 'bullet',
      links: links,
      permissions: this.buildPermissions(json),
      searchable: json.searchable,
      defInfo: Boolean(json.definfo || json.isdefinfo),
      infoAutoTranslated: Boolean(json.infoautotranslated),
      defText: Boolean(json.deftext || json.isdeftext),
      textAutoTranslated: Boolean(json.textautotranslated),
      defLinks: Boolean(json.deflink),
      anyTranslatedInfo: Boolean(json.anytranslatedinfo || json.anytranslatedinfo),
      anyTranslatedText: Boolean(json.anytranslatedtext  || json.anytranslatedtext),
      anyTranslatedLink: Boolean(json.anytranslatedlink || json.anytranslatedlink),
      writeable: Boolean(json.writeable),
      labels: json.labels?.own || [],
      descendentLabels: json.labels?.descendents || [],
      fullyLabeled: json.fullyLabeled ?? json.fullylabeled ?? null,
      ancestorLabels: parentLabels,
      backup: Boolean(json.backup)
    };
  }

  private buildPath(json: any) {
    const pathNames: any[] = json.path;
    const pathIds: any[] = json.pathids;

    if (pathNames.length !== pathIds.length) {
      throw new Error('Path names and IDs not same length');
    }

    const path: PathElement[] = [];
    for (let i = 0; i < pathIds.length; i++) {
      let pathName = pathNames[i];
      if (pathName === CustomerTreeBuilder.PM_BACKUP_NODE) {
        pathName = this.translate.instant('tree-builder.backup.node');
      }
      path.push(new PathElement(String(pathIds[i]), pathName || ''));
    }

    return path;
  }

  private getBackupText(backup: boolean) {
    if (backup) {
      return this.translate.instant('tree-builder.backup.node');
    }
    return ''
  }
}
