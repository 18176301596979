export enum EventTypes {
  LABEL_CREATED = 'labelCreated',
  LABEL_DELETED = 'labelDeleted',
  LABEL_UPDATED = 'labelUpdated',
  NODE_DELETED = 'nodeDeleted',
  NODE_ADDED = 'nodeAdded',
  NODE_MOVED = 'nodeMoved',
  NODE_COPIED = 'nodeCopied',
  NODE_STYLE_CHANGED = 'nodeRestyled',
  NODE_LABELS_CHANGED = 'nodeLabelsChanged',
  NODE_LINK_ADDED = 'linkAdded',
  NODE_LINK_DELETED = 'linkDeleted',
  NODE_TEXT_CHANGED = 'nodeTextChanged',
  NODE_INFO_ADDED = 'infoTextAdded',
  NODE_INFO_UPDATED = 'infoTextUpdated',
  NODE_INFO_REMOVED = 'infoTextRemoved',
  NODE_INFO_COLOR_CHANGED = 'infoColorChange',
  RESOURCE_COLOR_CHANGED = 'resourceColorChange',
  ROW_RESIZED = 'rowResized'
}

export interface HistoryEventBlock {
  datetime: string;
  user: string;
  commitId: number;
  events: HistoryEvent[];
}

export interface HistoryEvent {
  eventType: string;
  details: object
}

export interface LabelEvent extends HistoryEvent {
  details: {
    labelId: number;
    labelName: string;
    labelColor: string,
    labelExtension: boolean,
    labelIsTrial: boolean,
  }
}

export interface LabelCreatedOrDeleted extends LabelEvent {
  eventType: EventTypes.LABEL_CREATED | EventTypes.LABEL_DELETED;
}

export interface LabelUpdated extends LabelEvent {
  eventType: EventTypes.LABEL_UPDATED;
  details: LabelEvent['details'] & {
    oldLabelColor: string,
    oldLabelExtension: boolean,
    oldLabelIsTrial: boolean,
  }
}

export interface NodeDetails {
  nodeId: number;
  nodeType: string;
  nodeName: string;
  stillExists: boolean;
  searchable: boolean;
  nodeParentId: number;
  nodeSortOrder: number;
  nodeEmail: string;
}

export interface NodeEvent extends HistoryEvent {
  details: NodeDetails
}

export interface NodeAddedDeleted extends NodeEvent {
  eventType: EventTypes.NODE_DELETED | EventTypes.NODE_ADDED;
}

export interface NodeMovedOrCopied extends NodeEvent {
  eventType: EventTypes.NODE_MOVED | EventTypes.NODE_COPIED;
  details: NodeDetails & {
    oldNodeParentId: number,
    oldNodeSortOrder: number,
    oldNodeParentName: string
  }
}

export interface RowResized extends NodeEvent {
  eventType: EventTypes.ROW_RESIZED;
  details: NodeDetails & {
    nodeSize: {
      width: number,
      height: number,
    },
    oldNodeSize: {
      width: number,
      height: number,
    },
  }
}

export interface HistoryStyle {
  id: number,
  shape: string,
  lineColor: string,
  bgColor: string,
}

export interface NodeStyleChanged extends NodeEvent {
  eventType: EventTypes.NODE_STYLE_CHANGED;
  details: NodeDetails & {
    oldStyle: HistoryStyle,
    style: HistoryStyle
  }
}

export interface NodeLabelsChanged extends NodeEvent {
  eventType: EventTypes.NODE_LABELS_CHANGED;
  details: NodeDetails & {
    labels: number[],
    oldLabels: number[],
    labelNames: { labelId: number, labelName: string }[]
  }
}

export interface NodeLinksChanged extends NodeEvent {
  eventType: EventTypes.NODE_LINK_ADDED | EventTypes.NODE_LINK_DELETED;
  details: NodeDetails & {
    resourceName: string,
    resourceId: number,
    resourceType: string
  }
}

export interface NodeRenamed extends NodeEvent {
  eventType: EventTypes.NODE_TEXT_CHANGED;
  details: NodeDetails & {
    oldNodeName: string
  }
}

export interface NodeInfoChanged extends NodeEvent {
  eventType: EventTypes.NODE_INFO_UPDATED | EventTypes.NODE_INFO_COLOR_CHANGED;
  details: NodeDetails & {
    info: string,
    oldInfo?: string,
    extendedInfo: string,
    oldExtendedInfo?: string,
    infoColor: string,
    oldInfoColor?: string,
  }
}

export interface NodeInfoAddedRemoved extends NodeEvent {
  eventType: EventTypes.NODE_INFO_ADDED | EventTypes.NODE_INFO_REMOVED;
  details: NodeDetails & {
    info: string,
    extendedInfo: string,
    infoColor: string,
  }
}

export interface EventLink {
  nodeId: number;
  nodeName: string;
  nodeType: string;
  stillExists: boolean;
}

export interface ResourceColorChanged extends HistoryEvent {
  eventType: EventTypes.RESOURCE_COLOR_CHANGED,
  details: {
    resourceId: number,
    resourceType: string,
    resourceName: string,
    resourceColor: string,
    oldResourceColor: string,
    links: EventLink[]
  }
}
